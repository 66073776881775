import { useCallback, useEffect, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { fetchApi } from '~technical/api';

import { IPackConfig } from './types';

export const useMonetization = (packConfig: IPackConfig) => {
  const [isMonetizationEnabled, setMonetizationEnabled] = useState(
    !!packConfig?.isMonetizationEnabled
  );
  const [hasExistingPacks, setHasExistingPacks] = useState<boolean>(true);
  const [canCreatePacks, setCanCreatePacks] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  useEffect(() => {
    setCanCreatePacks(
      !!(packConfig?.id && isMonetizationEnabled && !hasExistingPacks)
    );
  }, [packConfig, hasExistingPacks, isMonetizationEnabled]);

  useEffect(() => {
    if (packConfig?.id && isMonetizationEnabled) {
      (async () => {
        const response = await fetchApi(
          `/backoffice/pack-config/${packConfig.id}/pack-exist`
        );
        const data = await response.json();
        setHasExistingPacks(data.hasExistingPacks);
      })();
    }
  }, [packConfig, isMonetizationEnabled, setHasExistingPacks]);

  const createPacks = useCallback(async () => {
    setCanCreatePacks(false);
    try {
      await fetchApi(
        `/backoffice/pack-config/${packConfig.id}/create-monetized-packs`
      );
      notify('Packs created', { type: 'success' });
    } catch (e: any) {
      notify(`Failed to create packs (${e.message})`, { type: 'warning' });
    }
    refresh();
  }, [packConfig, notify, refresh]);

  return {
    isMonetizationEnabled,
    setMonetizationEnabled,
    hasExistingPacks,
    canCreatePacks,
    createPacks,
  };
};
