import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import styled from 'styled-components';
import { useBoolean } from 'usehooks-ts';

import { LootTableField } from './LootTableField';
import { useMonetization } from './hooks';
import { IPackConfig } from './types';

const noop = () => {};

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * {
    margin-left: 1.5em;
  }
`;

const CreatePacksButton = (props: { record: IPackConfig }) => {
  const { record } = props;
  const { canCreatePacks, createPacks } = useMonetization(record);
  const {
    value: mustConfirm,
    setTrue: setMustConfirmTrue,
    setFalse: setMustConfirmFalse,
  } = useBoolean(false);

  if (!record.monetization?.supply) {
    return null;
  }
  return (
    <>
      <Button
        disabled={!canCreatePacks}
        label="Create packs"
        onClick={!canCreatePacks ? noop : setMustConfirmTrue}
      >
        <SendIcon />
      </Button>

      <Confirm
        isOpen={mustConfirm}
        title="Packs creation"
        content="Please confirm packs creation."
        onConfirm={() => {
          setMustConfirmFalse();
          createPacks();
        }}
        onClose={setMustConfirmFalse}
      />
    </>
  );
};

const Actions = (props: any) => (
  <ActionsWrapper>
    <EditButton {...props} />
    <CreatePacksButton {...props} />
  </ActionsWrapper>
);

export const PackConfigList = (props: {}) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <ReferenceField source="lootTable" reference="lootTable" link={false}>
        <LootTableField source="googleSheetTitle" />
      </ReferenceField>
      <Actions />
    </Datagrid>
  </List>
);
