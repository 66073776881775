"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withdrawToken = exports.token = exports.weiAmount = void 0;
var technical_1 = require("../../technical");
var type_1 = require("./type");
exports.weiAmount = {
    numericality: {
        greaterThan: 0,
    },
    presence: {
        allowEmpty: false,
    },
};
exports.token = {
    inclusion: technical_1.enumToArray(type_1.Token),
    presence: {
        allowEmpty: false,
    },
};
exports.withdrawToken = {
    to: technical_1.address,
    weiAmount: exports.weiAmount,
    token: exports.token,
};
