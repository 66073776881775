import { Button } from '@material-ui/core';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  filter: {
    season?: string;
    batch?: string;
  };
}

const ShowNftButton = ({ filter }: IProps) => (
  <Button
    color="primary"
    variant="contained"
    component={Link}
    to={{
      pathname: '/nftcard',
      search: `filter=${JSON.stringify(filter)}`,
    }}
    startIcon={<CropPortraitIcon />}
  >
    Show NFTs
  </Button>
);

export default ShowNftButton;
