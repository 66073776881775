import { makeStyles } from '@material-ui/core/styles';

export const useCardGridListStyles = makeStyles(() => ({
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: 12,
      padding: 24,
      boxSizing: 'border-box',
      borderRadius: 10,
      height: 300,
      width: 200,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      position: 'relative',
    },
    '& .actions': {
      opacity: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 12,
      transition: 'all 0.3s ease',
    },
    '& .minting': {
      opacity: 1,
    },
    '& > *:hover .actions': {
      opacity: 1,
    },
    '& .actions > button': {
      background: 'white',
    },
    '& .actions > a': {
      margin: '0 4px',
      background: 'white',
    },
  },
}));
