import { processGenericFilter, Value } from './generic';

export enum LootTableField {
  GOOGLE_SHEET_TITLE = 'googleSheetTitle',
}

export function processLootTableFilter(
  field: LootTableField | string,
  value: Value
) {
  switch (field) {
    case LootTableField.GOOGLE_SHEET_TITLE:
      return { googleSheetTitle: { $regex: value, $options: 'i' } };
    default:
      return processGenericFilter(field, value);
  }
}
