import { processAthleteFilter } from '~technical/filters/athlete';
import { processNftBatchFilter } from '~technical/filters/batch';
import { processGenericFilter } from '~technical/filters/generic';
import { processLootTableFilter } from '~technical/filters/lootTable';
import { processCardFilter } from '~technical/filters/nftCard';
import { processNftSeasonFilter } from '~technical/filters/season';
import { processTeamFilter } from '~technical/filters/team';
import { processUserFilter } from '~technical/filters/user';

import { buildQuery, fetchApi, submitData } from './api';

type Value = string | number | object;

interface IMongoRecord {
  _id: Value;
  [field: string]: Value;
}

interface IReactAdminRecord {
  id: Value;
  [field: string]: Value;
}

function mapId(
  data: IMongoRecord | IMongoRecord[]
): IReactAdminRecord | IReactAdminRecord[] {
  if (data instanceof Array) {
    return data.map(mapId) as IReactAdminRecord[];
  }

  return {
    ...data,
    // eslint-disable-next-line no-underscore-dangle
    id: data._id,
  };
}

interface IPagination {
  page: number;
  perPage: number;
}

function getPagination({ perPage, page }: IPagination) {
  return {
    skip: perPage * (page - 1),
    limit: perPage,
  };
}

interface ISort {
  field: string;
  order: string | 'ASC' | 'DESC';
}

function getSort({ field, order }: ISort) {
  return {
    sort: `${order === 'ASC' ? '' : '-'}${field}`,
  };
}

interface IFilters {
  [field: string]: Value;
}

function processFilter(resource: string, field: string, value: Value) {
  switch (resource) {
    case 'athlete':
      return processAthleteFilter(field, value);
    case 'team':
      return processTeamFilter(field, value);
    case 'NftCard':
      return processCardFilter(field, value);
    case 'nftcardseason':
      return processNftSeasonFilter(field, value);
    case 'nftbatch':
      return processNftBatchFilter(field, value);
    case 'lootTable':
      return processLootTableFilter(field, value);
    case 'user':
      return processUserFilter(field, value);
    default:
      return processGenericFilter(field, value);
  }
}

export function processFilters(resource: string, filter: IFilters) {
  return Object.keys(filter).reduce(
    (acc, field) => ({
      ...acc,
      ...processFilter(resource, field, filter[field]),
    }),
    {}
  );
}

function getQuery(resource: string, filter: IFilters) {
  const processedFilter = processFilters(resource, filter);
  const fullQuery =
    Object.keys(processedFilter).length === 0
      ? {}
      : {
          $and: Object.keys(processedFilter).map((field) => ({
            [field]: processedFilter[field],
          })),
        };

  return {
    query: JSON.stringify(fullQuery),
  };
}

interface IGetListParams {
  pagination: IPagination;
  sort: ISort;
  filter: IFilters;
}

function getTotal(response: Response) {
  const total = response.headers.get('X-Total-Count');

  return total ? parseInt(total, 10) : 0;
}

async function getList(
  resource: string,
  { pagination, sort, filter }: IGetListParams
) {
  const query = {
    ...getPagination(pagination),
    ...getSort(sort),
    ...getQuery(resource, filter),
  };

  const response = await fetchApi(
    `/backoffice/${resource}?${buildQuery(query)}`
  );

  return {
    data: mapId(await response.json()),
    total: getTotal(response),
  };
}

interface IGetOneParams {
  id: Value;
}

async function getOne(resource: string, { id }: IGetOneParams) {
  const response = await fetchApi(`/backoffice/${resource}/${id}`);
  return {
    data: mapId(await response.json()),
  };
}

interface IGetManyParams {
  ids: Value[];
}

async function getMany(resource: string, { ids }: IGetManyParams) {
  const query = {
    query: JSON.stringify({
      _id: { $in: ids },
    }),
  };

  const response = await fetchApi(
    `/backoffice/${resource}?${buildQuery(query)}`
  );
  return {
    data: mapId(await response.json()),
  };
}

interface IGetManyReferenceParams {
  target: string;
  id: Value;
  pagination: IPagination;
  sort: ISort;
  filter: IFilters;
}

async function getManyReference(
  resource: string,
  { target, id, pagination, sort, filter }: IGetManyReferenceParams
) {
  const query = {
    ...getPagination(pagination),
    ...getSort(sort),
    ...processFilters(resource, {
      ...filter,
      [target]: id,
    }),
  };

  const response = await fetchApi(
    `/backoffice/${resource}?${buildQuery(query)}`
  );
  return {
    data: mapId(await response.json()),
    total: getTotal(response),
  };
}

interface ICreateParams {
  data: object;
}

async function create(resource: string, { data }: ICreateParams) {
  return {
    data: mapId(await submitData(`/backoffice/${resource}`, data)),
  };
}

interface IUpdateParams {
  id: Value;
  data: object;
  previousData?: object;
}

async function update(resource: string, { id, data }: IUpdateParams) {
  return {
    data: mapId(
      await submitData(`/backoffice/${resource}/${id}`, data, 'PATCH')
    ),
  };
}

interface IUpdateManyParams {
  ids: Value[];
  data: object;
}

async function updateMany(resource: string, { ids, data }: IUpdateManyParams) {
  return {
    data: mapId(
      await Promise.all(
        ids.map((id) =>
          submitData(`/backoffice/${resource}/${id}`, data, 'PATCH')
        )
      )
    ),
  };
}

interface IDeleteParams {
  id: Value;
  previousData?: object;
}

async function deleteF(resource: string, { id, previousData }: IDeleteParams) {
  await fetchApi(`/backoffice/${resource}/${id}`, { method: 'DELETE' });

  return {
    data: {
      id,
      ...previousData,
    },
  };
}

interface IDeleteManyParams {
  ids: Value[];
}

async function deleteMany(resource: string, { ids }: IDeleteManyParams) {
  await Promise.all(ids.map((id) => deleteF(resource, { id })));

  return {
    data: ids.map((id) => ({ id })),
  };
}

export const dataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  updateMany,
  delete: deleteF,
  deleteMany,
};
