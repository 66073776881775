import { processGenericFilter, Value } from './generic';

export enum UserField {
  USERNAME = 'username',
}

export function processUserFilter(field: UserField | string, value: Value) {
  switch (field) {
    case UserField.USERNAME:
      return { username: { $regex: value, $options: 'i' } };

    default:
      return processGenericFilter(field, value);
  }
}
