import SendIcon from '@material-ui/icons/Send';
import React, { useCallback, useState } from 'react';
import {
  Button,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  ShowButton,
  TextField,
  useListContext,
  useRedirect,
} from 'react-admin';
import styled from 'styled-components';
import { IBatchIngredientError } from 'ultimate-league-common';
import { fetchApi } from '~technical/api';
import { error } from '~technical/logger';

import { getAthletesCount, hasCustomAssets, hasCustomTemplate } from './common';
import { PublicationErrors } from './components/publication-errors';
import { ISavedNftBatch } from './types';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface IActionsProps {
  record: ISavedNftBatch;
  publish: (batch: ISavedNftBatch) => Promise<void>;
}

const Actions = ({ publish, record, ...restProps }: IActionsProps) => {
  const [publishing, setPublishing] = useState(false);
  const handlePublish = useCallback(async () => {
    setPublishing(true);
    await publish(record);
    setPublishing(false);
  }, [publish, setPublishing, record]);

  return (
    <ActionsWrapper>
      <ShowButton record={record} {...restProps} />
      {record.isLive !== true && (
        <>
          <EditButton record={record} {...restProps} />
          <Button
            label={publishing ? 'Publishing...' : 'Publish'}
            disabled={publishing}
            onClick={handlePublish}
          >
            <SendIcon />
          </Button>
        </>
      )}
    </ActionsWrapper>
  );
};

const CustomIterator = () => {
  const { refetch } = useListContext();
  const redirect = useRedirect();

  const [errors, setErrors] = useState<IBatchIngredientError[]>([]);
  const [erroredBatch, setErroredBatch] = useState<
    ISavedNftBatch | undefined
  >();

  async function publish(batch: ISavedNftBatch) {
    try {
      const response = await fetchApi(
        `/backoffice/nftbatch/${batch.id}/publish`
      );
      const publishResult = await response.json();
      if (Array.isArray(publishResult?.errors) && publishResult.errors.length) {
        setErroredBatch(batch);
        setErrors(publishResult.errors);
        window.setTimeout(refetch, 500);
      } else {
        redirect(
          'list',
          `/nftcard?filter=${JSON.stringify({ batch: batch.id })}`
        );
      }
    } catch (e) {
      error(e);
      setErroredBatch(batch);
      setErrors([{ reason: e.message }]);
    }
  }

  return (
    <>
      <PublicationErrors erroredBatch={erroredBatch} errors={errors} />

      <Datagrid>
        <TextField source="label" />

        <ReferenceField source="season" reference="nftcardseason">
          <TextField source="title" />
        </ReferenceField>

        <FunctionField
          label="Athletes count"
          render={(batch: any) => getAthletesCount(batch)}
        />
        <FunctionField
          label="Has custom assets"
          render={(batch: any) => (hasCustomAssets(batch) ? 'Yes' : 'No')}
        />
        <FunctionField
          label="Has custom template"
          render={(batch: any) => (hasCustomTemplate(batch) ? 'Yes' : 'No')}
        />
        <FunctionField
          label="Published"
          render={(batch: any) => (batch.isLive === true ? 'Yes' : 'No')}
        />

        {/* @ts-ignore */}
        <Actions publish={publish} />
      </Datagrid>
    </>
  );
};

export const NftBatchList = (props: {}) => (
  <List {...props} exporter={false}>
    <CustomIterator />
  </List>
);
