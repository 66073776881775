import { Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import {
  BooleanField,
  DateField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import ShowNftButton from '~business/common/components/ShowNftButton';
import ShowRarityAssets from '~business/common/components/ShowRarityAssets';

const AvailableCards = ({ record: { id, isLive } }: any) => (
  <>
    {!isLive ? (
      <Typography variant="subtitle1">Season not published yet</Typography>
    ) : (
      <Toolbar>
        <ShowNftButton filter={{ season: id }} />
      </Toolbar>
    )}
  </>
);

export const SeasonShow = (props: any) => {
  const { record } = useShowController(props);
  return (
    <Show {...props} hasEdit={false}>
      <TabbedShowLayout>
        <Tab label="summary">
          <BooleanField source="isLive" />
          <TextField source="title" />
          <DateField source="startDate" showTime />
          <DateField source="endDate" showTime />
        </Tab>
        <Tab label="Rarities">
          <ShowRarityAssets rarities={record?.rarities} />
        </Tab>
        <Tab label="Cards">
          <AvailableCards />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
