import React, { PropsWithChildren } from 'react';
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { IInputPositionAssets } from '~business/common/assetTypes';
import { StorageImageInput } from '~business/storage/StorageImageInput';

import {
  PositionLabel,
  StorageImageInputContainer,
  PositionAssetsWrapper,
} from './PositionsInputIterator.styles';

const PositionsInputIterator = ({
  fields,
}: PropsWithChildren<
  FieldArrayRenderProps<IInputPositionAssets, HTMLDivElement>
>) =>
  fields.map((value) => (
    <PositionAssetsWrapper key={value}>
      <PositionLabel
        disabled
        source={`${value}.position`}
        label=""
        format={(v: string) => `${v} ASSETS`}
      />
      <StorageImageInputContainer display="flex">
        <ArrayInput source={`${value}.cardAssets`} label="">
          <SimpleFormIterator>
            <StorageImageInput label="Asset" source="file" />
          </SimpleFormIterator>
        </ArrayInput>
      </StorageImageInputContainer>
    </PositionAssetsWrapper>
  ));

export default PositionsInputIterator;
