import React from 'react';
import {
  Edit,
  EditProps,
  ReferenceField,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
} from 'react-admin';
import { enumToArray, User } from 'ultimate-league-common';
import { WalletStatus } from '~business/wallet/WalletStatus';

const roleChoices = enumToArray(User.Role).map((role) => ({
  id: role,
  name: role,
}));

const UserForm = ({ record, ...props }: SimpleFormProps) => (
  <SimpleForm record={record} {...props}>
    <TextInput source="username" />
    <SelectInput source="role" choices={roleChoices} />
    <ReferenceField reference="wallet" source="wallet" link={false}>
      <WalletStatus ownerId={record.id} />
    </ReferenceField>
  </SimpleForm>
);

export const UserEdit = (props: EditProps) => (
  <Edit {...props}>
    {
      // @ts-ignore
      <UserForm />
    }
  </Edit>
);
