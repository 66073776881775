"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAthletes = void 0;
exports.getAthletes = {
    q: {
        type: 'string',
        length: {
            minimum: 3,
            maximum: 30,
        },
        presence: {
            allowEmpty: false,
        },
    },
};
