import { processGenericFilter, Value } from './generic';

export enum CardField {
  SEARCH_LEVEL = 'level',
  SEARCH_ATHLETE = 'athlete',
  SEARCH_ATHLETE_POSITION = 'search_athletePosition',
  SEARCH_ATHLETE_AGE = 'search_athleteAge',
  SEARCH_TEAM = 'search_team',
  SEARCH_SEASON = 'season',
  SEARCH_BATCH = 'batch',
}

export function processCardFilter(field: CardField | string, value: Value) {
  switch (field) {
    case CardField.SEARCH_ATHLETE_AGE:
      return {
        'search.athleteAge': {
          $gte: value[0],
          $lte: value[1],
        },
      };
    case CardField.SEARCH_TEAM:
      return {
        $or: [
          {
            'search.club': value,
          },
          {
            'search.national': value,
          },
        ],
      };
    default:
      return processGenericFilter(field.replace('_', '.'), value);
  }
}
