import { Typography } from '@material-ui/core';
import React from 'react';
import {
  DateField,
  NumberField,
  ReferenceField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import styled from 'styled-components';
import { Blockchain, Storage } from 'ultimate-league-common';
import { footChoices, positionChoices } from '~business/athlete/common';
import { IRarityAsset } from '~business/common/assetTypes';
import { StorageField } from '~business/storage/StorageField';
import { AssetField } from '~ui/AssetField';

const StyledTypography = styled(Typography)`
  margin: 2em 0 1em;
`;

const RarityAssets = (props: any) => {
  const { record } = props;

  if (!record || Array.isArray(record?.assets?.rarities) !== true) {
    return (
      <StyledTypography variant="body1">
        No rarity assets for this athlete
      </StyledTypography>
    );
  }
  return record.assets.rarities?.map((rarityAsset: IRarityAsset) => (
    <div key={rarityAsset.rarity}>
      <StyledTypography>
        <b>{Blockchain.NFTCard.RarityLevel[rarityAsset.rarity]}</b>
      </StyledTypography>
      {!rarityAsset.file ? (
        <Typography variant="body1">No asset uploaded</Typography>
      ) : (
        <StorageField record={rarityAsset.file} />
      )}
    </div>
  ));
};

export const AthleteShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="matchName" />
        <SelectField source="position" choices={positionChoices} />
        <ReferenceField source="club" reference="team" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="national" reference="team" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <AssetField
          assetParams={{
            storageType: Storage.StorageType.PUBLIC_ATHLETE_MAIN,
            format: Storage.AssetFormat.MEDIUM,
          }}
          source="assets.main"
        />
      </Tab>

      <Tab label="Card assets">
        <RarityAssets />
      </Tab>

      <Tab label="refs">
        <TextField source="optaId" />
      </Tab>

      <Tab label="data">
        <NumberField source="tier" />
        <DateField source="birth" />
        <NumberField source="height" />
        <NumberField source="weight" />
        <SelectField source="foot" choices={footChoices} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
