"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatScore = exports.computePlayerScore = exports.computeModifiers = exports.computeAthleteScore = exports.computeCategory = void 0;
function computeCategory(score) {
    return Object.keys(score).reduce(function (totalScore, statKey) { var _a; return totalScore + (((_a = score[statKey]) === null || _a === void 0 ? void 0 : _a.value) || 0); }, 0);
}
exports.computeCategory = computeCategory;
function computeAthleteScore(score) {
    return Math.max(0, Object.keys(score).reduce(function (totalScore, category) { return totalScore + computeCategory(score[category]); }, 0));
}
exports.computeAthleteScore = computeAthleteScore;
function computeModifiers(_a, modifierConfig) {
    var _b, _c;
    var nftEdition = _a.nftEdition, nftRarity = _a.nftRarity, nftSeason = _a.nftSeason, isCaptain = _a.isCaptain;
    var seasonModifierValue = modifierConfig.seasons.current -
        (new Date().getFullYear() - new Date(nftSeason.endDate).getFullYear()) *
            modifierConfig.seasons.pastX;
    var rarityModifierValue = ((_b = modifierConfig.rarities.find(function (_a) {
        var rarityLevel = _a.rarityLevel;
        return rarityLevel === nftRarity;
    })) === null || _b === void 0 ? void 0 : _b.modifierValue) || 0;
    var editionModifierValue = ((_c = modifierConfig.editions.find(function (_a) {
        var edition = _a.edition;
        return edition === nftEdition;
    })) === null || _c === void 0 ? void 0 : _c.modifierValue) || 0;
    var captainModifierValue = isCaptain ? modifierConfig.team.captain : 0;
    var totalModifierValue = seasonModifierValue +
        rarityModifierValue +
        editionModifierValue +
        captainModifierValue;
    return {
        seasonModifierValue: seasonModifierValue,
        rarityModifierValue: rarityModifierValue,
        editionModifierValue: editionModifierValue,
        totalModifierValue: totalModifierValue,
    };
}
exports.computeModifiers = computeModifiers;
function computePlayerScore(_a, modifierConfig) {
    var athleteScore = _a.athleteScore, params = __rest(_a, ["athleteScore"]);
    var computedModifiers = computeModifiers(params, modifierConfig);
    var score = (athleteScore * computedModifiers.totalModifierValue) / 100 + athleteScore;
    return __assign(__assign({}, computedModifiers), { score: score, displayScore: formatScore(score) });
}
exports.computePlayerScore = computePlayerScore;
function formatScore(score) {
    return String(Math.round(score));
}
exports.formatScore = formatScore;
