import React from 'react';
import { Datagrid, DateField, List, ShowButton, TextField } from 'react-admin';
import { PresenceField } from '~ui/PresenceField';

import { DurationField } from './DurationField';

export const CronList = (props: {}) => (
  <List sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
    <Datagrid>
      <TextField source="job" />
      <DateField source="createdAt" showTime />
      <DurationField source="duration" />
      <TextField source="logs" />
      <PresenceField source="error" />
      <ShowButton />
    </Datagrid>
  </List>
);
