"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.id = void 0;
exports.id = {
    format: {
        pattern: /^[a-f\d]{24}$/i,
    },
    presence: {
        allowEmpty: false,
    },
};
