"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractEventListener = void 0;
var technical_1 = require("../technical");
var contract_1 = require("./contract");
var ContractEventListener = /** @class */ (function () {
    function ContractEventListener(contractName, eventName, contract, loadState, saveState, Logger) {
        this.state = {
            lastFetchedBlock: 0,
        };
        this.listening = false;
        this.contractName = contractName;
        this.eventName = eventName;
        this.contract = contract;
        this.loadState = loadState;
        this.saveState = saveState;
        this.Logger = Logger;
    }
    ContractEventListener.prototype.listenEvents = function (onEvent, options) {
        return __awaiter(this, void 0, void 0, function () {
            var loadedState;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadState(this.contractName, this.eventName)];
                    case 1:
                        loadedState = _a.sent();
                        if (loadedState) {
                            this.state = loadedState;
                        }
                        this.listening = true;
                        return [2 /*return*/, technical_1.timeoutLoop({
                                jobTitle: "[EventListener] " + this.contractName + "." + this.eventName,
                                job: function () { return _this.fetchAndDispatch(onEvent, options); },
                                intervalMs: ContractEventListener.INTERVAL_MS,
                                timeoutMs: ContractEventListener.TIMEOUT_MS,
                                onStop: function () {
                                    if (_this.listening) {
                                        _this.listening = false;
                                        _this.saveState(_this.contractName, _this.eventName, _this.state).catch(_this.Logger.critical);
                                    }
                                },
                                Logger: this.Logger,
                            })];
                }
            });
        });
    };
    ContractEventListener.prototype.fetchAndDispatch = function (onEvent, options) {
        return __awaiter(this, void 0, void 0, function () {
            var events, _i, events_1, e, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        this.Logger.debug("[EventListener] " + this.contractName + "." + this.eventName + " fetchAndDispatch");
                        return [4 /*yield*/, this.fetchLatestEvents(options)];
                    case 1:
                        events = _a.sent();
                        if (!this.listening) return [3 /*break*/, 5];
                        _i = 0, events_1 = events;
                        _a.label = 2;
                    case 2:
                        if (!(_i < events_1.length)) return [3 /*break*/, 5];
                        e = events_1[_i];
                        return [4 /*yield*/, onEvent(e)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        this.Logger.warn(e_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ContractEventListener.prototype.fetchLatestEvents = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var fromBlock, toBlock, events, lastBlock;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fromBlock = this.state.lastFetchedBlock;
                        return [4 /*yield*/, contract_1.web3.eth.getBlockNumber()];
                    case 1:
                        toBlock = _a.sent();
                        return [4 /*yield*/, this.getPastEvents(__assign({ fromBlock: fromBlock,
                                toBlock: toBlock }, options))];
                    case 2:
                        events = _a.sent();
                        lastBlock = Math.max.apply(Math, __spreadArray([toBlock], events.map(function (_a) {
                            var blockNumber = _a.blockNumber;
                            return blockNumber;
                        })));
                        if (this.listening) {
                            this.state.lastFetchedBlock = lastBlock + 1;
                        }
                        return [2 /*return*/, events];
                }
            });
        });
    };
    ContractEventListener.prototype.getPastEvents = function (_a) {
        var fromBlock = _a.fromBlock, toBlock = _a.toBlock, options = __rest(_a, ["fromBlock", "toBlock"]);
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                return [2 /*return*/, Array.from(Array(Math.ceil((1 + toBlock - fromBlock) / ContractEventListener.MAX_BLOCKS_DIFF))).reduce(function (events, _, index) { return __awaiter(_this, void 0, void 0, function () {
                        var previousEvents, getPastEventsOptions, nextEvents;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, events];
                                case 1:
                                    previousEvents = _a.sent();
                                    getPastEventsOptions = __assign({ fromBlock: fromBlock + index * ContractEventListener.MAX_BLOCKS_DIFF, toBlock: Math.min(toBlock, fromBlock + (index + 1) * ContractEventListener.MAX_BLOCKS_DIFF - 1) }, options);
                                    this.Logger.debug("[EventListener] " + this.contractName + "." + this.eventName + " getPastEvents: Fetching events in blocks", getPastEventsOptions);
                                    return [4 /*yield*/, this.contract.getPastEvents(this.eventName, getPastEventsOptions)];
                                case 2:
                                    nextEvents = _a.sent();
                                    this.Logger.debug("[EventListener] " + this.contractName + "." + this.eventName + " getPastEvents: Received " + nextEvents.length + " event(s)");
                                    return [2 /*return*/, __spreadArray(__spreadArray([], previousEvents), nextEvents)];
                            }
                        });
                    }); }, Promise.resolve([]))];
            });
        });
    };
    ContractEventListener.MAX_BLOCKS_DIFF = 10000;
    ContractEventListener.INTERVAL_MS = 10000;
    ContractEventListener.TIMEOUT_MS = 60000;
    return ContractEventListener;
}());
exports.ContractEventListener = ContractEventListener;
