"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerformanceLevel = exports.Category = void 0;
var Category;
(function (Category) {
    Category["STARTING"] = "STARTING";
    Category["CLUB"] = "CLUB";
    Category["ERRORS"] = "ERRORS";
    Category["POSSESSIVE"] = "POSSESSIVE";
    Category["OFFENSIVE"] = "OFFENSIVE";
    Category["DEFENSIVE"] = "DEFENSIVE";
    Category["GOALKEEPING"] = "GOALKEEPING";
    Category["HIDDEN"] = "HIDDEN";
})(Category = exports.Category || (exports.Category = {}));
var PerformanceLevel;
(function (PerformanceLevel) {
    PerformanceLevel["LOW"] = "Low";
    PerformanceLevel["MEDIUM"] = "Medium";
    PerformanceLevel["HIGH"] = "High";
    PerformanceLevel["NA"] = "NotAvailable";
})(PerformanceLevel = exports.PerformanceLevel || (exports.PerformanceLevel = {}));
