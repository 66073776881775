"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.directTokenPackBuy = exports.createIntentPackBuy = exports.createIntentMarketBuy = void 0;
var NFT_card_1 = require("../blockchain/NFT-card");
var token_1 = require("../blockchain/token");
var technical_1 = require("../technical");
var endpoint_1 = require("./endpoint");
exports.createIntentMarketBuy = {
    nft: NFT_card_1.NFT,
    method: {
        inclusion: technical_1.enumToArray(endpoint_1.Method),
        presence: {
            allowEmpty: false,
        },
    },
    payment: {
        type: 'string',
    },
};
var quantityValidation = {
    presence: {
        allowEmpty: false,
    },
    numericality: {
        onlyInteger: true,
        greaterThan: 0,
    },
};
exports.createIntentPackBuy = {
    packConfigId: technical_1.id,
    quantity: quantityValidation,
    method: {
        inclusion: technical_1.enumToArray(endpoint_1.Method),
        presence: {
            allowEmpty: false,
        },
    },
    payment: {
        type: 'string',
    },
};
exports.directTokenPackBuy = {
    packConfigId: technical_1.id,
    quantity: quantityValidation,
    token: {
        inclusion: technical_1.enumToArray(token_1.Token),
        presence: {
            allowEmpty: false,
        },
    },
    allowedWeiAmount: {
        type: 'string',
        presence: {
            allowEmpty: false,
        },
    },
};
