import {
  Blockchain,
  INftIngredients,
  generateNftIngredients,
  getNationalFlagUrl,
} from 'ultimate-league-common';
import { IAthlete } from '~business/athlete/types';
import {
  ISavedCardAsset,
  ISavedRaritySupply,
} from '~business/common/assetTypes';

import { ISavedNftBatch, ISavedNftSeason } from '../../types';
import { getAssetPath, getAssetsWithPath } from './asset-helpers';
import { getFontsWithPath } from './font-helpers';

/* eslint-disable no-underscore-dangle */

const getAssetRarity = (
  rarities: ISavedRaritySupply[],
  rarityLevel: Blockchain.NFTCard.RarityLevel
) => rarities?.find((rarityAsset) => rarityAsset.level === rarityLevel);

export const mergeRarities = (batch: ISavedNftBatch, season: ISavedNftSeason) =>
  season.rarities
    .map((seasonRarity) => {
      const batchRarity = getAssetRarity(batch.rarities, seasonRarity.level);
      if (batchRarity) {
        return batchRarity;
      }
      return seasonRarity;
    })
    .filter((r) => r.supply > 0)
    .sort((r1, r2) => r1.level - r2.level);

async function getAssetsStorageId(
  athlete: IAthlete,
  rarity: ISavedRaritySupply
) {
  const athleteAsset = athlete.assets?.rarities?.find(
    (athRarityAsset) => athRarityAsset.rarity === rarity.level
  );
  if (!athleteAsset || !athleteAsset.file) {
    throw new Error(
      `Athlete has no asset for rarity ${
        Blockchain.NFTCard.RarityLevel[rarity.level]
      }`
    );
  }

  const clubAsset = athlete.club.logo?.medium || athlete.club.logo?.small;
  if (!clubAsset) {
    throw new Error(`Club has no logo`);
  }

  const cardAssetsForPosition = rarity.assetsPerPosition.find(
    (app) => app.position === athlete.position
  )?.cardAssets;
  if (!cardAssetsForPosition) {
    throw new Error(
      `Could not find card assets for position ${athlete.position}`
    );
  }

  return {
    athleteAssetId: athleteAsset.file,
    clubLogoAssetId: clubAsset,
    assets: cardAssetsForPosition,
  };
}

interface IParsedIngredients {
  ingredients: INftIngredients;
  assetIds: {
    athleteAssetId: string;
    clubLogoAssetId: string;
    assets: ISavedCardAsset[];
  };
}

export async function prepareIngredients(
  edition: number,
  athlete: IAthlete,
  season: ISavedNftSeason,
  currentRarity: ISavedRaritySupply
): Promise<IParsedIngredients> {
  const errors: string[] = [];

  if (!athlete.club) {
    errors.push('Club is missing');
  }
  if (!athlete.national) {
    errors.push('National team is missing');
  } else if (!athlete.national.code) {
    errors.push(`National team '${athlete.national.name}' has no code`);
  }
  if (!athlete.birth) {
    errors.push('Birth date is missing');
  }
  if (!athlete.position) {
    errors.push('Athlete position is missing');
  }
  if (errors.length) {
    throw new Error(errors.join(', '));
  }

  const { athleteAssetId, clubLogoAssetId, assets } = await getAssetsStorageId(
    athlete,
    currentRarity
  );

  const [athleteAssetPath, clubAssetPath] = await Promise.all(
    [athleteAssetId, clubLogoAssetId].map((id: string) => getAssetPath(id))
  );

  return {
    ingredients: {
      edition,
      ...generateNftIngredients({
        supply: currentRarity.supply,
        rarityLevel: currentRarity.level,
        athleteId: String(athlete._id),
        athleteName: athlete.matchName,
        athleteBirth: athlete.birth,
        athletePosition: String(athlete.position),
        athleteAsset: athleteAssetPath,
        seasonStartDate: season.startDate,
        seasonTitle: season.title,
        clubAsset: clubAssetPath,
        clubCode: athlete.club.code,
        clubName: athlete.club.name,
        nationalTeamAssetUrl: getNationalFlagUrl(athlete.national.code),
        nationalTeamName: athlete.national.name,
        nationalTeamCode: athlete.national.code,
        assets: await getAssetsWithPath(assets),
        fonts: await getFontsWithPath(season.cardFonts),
        cardTemplate: 'not used in this scenario',
      }),
    },
    assetIds: {
      athleteAssetId,
      clubLogoAssetId,
      assets,
    },
  };
}
