enum Host {
  DEV = 'localhost',
  PRE_PROD = 'dev.api.unagi.games',
  ALPHA = 'api.ultimate-champions.com',
  PROD = 'master.api.ultimate-champions.com',
}

export interface IConfig {
  title: string;
  background: string;
  color: string;
}

const allConfig: Record<Host, IConfig> = {
  [Host.DEV]: {
    title: '👨‍💻 Development 👨‍💻',
    background: 'white',
    color: 'black',
  },
  [Host.PRE_PROD]: {
    title: '⚠️ Pre-production ⚠️',
    background: 'orange',
    color: 'black',
  },
  [Host.ALPHA]: {
    title: '🚨 ALPHA 🚨',
    background: 'black',
    color: 'red',
  },
  [Host.PROD]: {
    title: '🚨 Production 🚨',
    background: 'black',
    color: 'red',
  },
};

const serverURL = new URL(process.env.SERVER || '');

export const config: IConfig | null = allConfig[serverURL.hostname] ?? null;
