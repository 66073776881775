import { FilterPayload } from 'react-admin';
import { Blockchain, Database, SoccerData } from 'ultimate-league-common';
import { CardField } from '~technical/filters/nftCard';

interface ICardQuery {
  level: Blockchain.NFTCard.RarityLevel;
  athlete?: Database.DocumentId;
  athletePosition?: SoccerData.Athlete.AthletePosition;
  athleteAge?: {
    minAge: number;
    maxAge: number;
  };
  team?: Database.DocumentId;
  season?: Database.DocumentId;
  batch?: Database.DocumentId;
}

enum CardQueryField {
  QUERY_LEVEL = 'level',
  QUERY_ATHLETE = 'athlete',
  QUERY_ATHLETE_POSITION = 'athletePosition',
  QUERY_ATHLETE_AGE = 'athleteAge',
  QUERY_TEAM = 'team',
  QUERY_SEASON = 'season',
  QUERY_BATCH = 'batch',
}

const FilterQueryMap: Record<CardField, CardQueryField> = {
  [CardField.SEARCH_LEVEL]: CardQueryField.QUERY_LEVEL,
  [CardField.SEARCH_ATHLETE]: CardQueryField.QUERY_ATHLETE,
  [CardField.SEARCH_ATHLETE_POSITION]: CardQueryField.QUERY_ATHLETE_POSITION,
  [CardField.SEARCH_ATHLETE_AGE]: CardQueryField.QUERY_ATHLETE_AGE,
  [CardField.SEARCH_TEAM]: CardQueryField.QUERY_TEAM,
  [CardField.SEARCH_SEASON]: CardQueryField.QUERY_SEASON,
  [CardField.SEARCH_BATCH]: CardQueryField.QUERY_BATCH,
};

export function reactAdminCardFilterToQuery(filter: FilterPayload) {
  const computedQuery: ICardQuery = {
    level: filter[CardField.SEARCH_LEVEL],
  };

  const filterAthleteAge = filter[CardField.SEARCH_ATHLETE_AGE];
  if (filterAthleteAge) {
    computedQuery.athleteAge = {
      minAge: filterAthleteAge[0],
      maxAge: filterAthleteAge[1],
    };
  }

  return Object.keys(filter).reduce(
    (query, key) => ({
      [FilterQueryMap[key]]: filter[key],
      ...query,
    }),
    computedQuery
  );
}

const QueryFilterMap: Record<CardQueryField, CardField> = Object.keys(
  FilterQueryMap
).reduce(
  (mapping, key) => ({
    ...mapping,
    [FilterQueryMap[key]]: key,
  }),
  {} as Record<CardQueryField, CardField>
);

export function queryToReactAdminFilter(query: ICardQuery) {
  const computedFilter: FilterPayload = {
    [CardField.SEARCH_LEVEL]: query.level,
  };

  if (query.athleteAge) {
    computedFilter[CardField.SEARCH_ATHLETE_AGE] = [
      query.athleteAge.minAge,
      query.athleteAge.maxAge,
    ];
  }

  return Object.keys(query).reduce(
    (filter, key) => ({
      [QueryFilterMap[key]]: query[key],
      ...filter,
    }),
    computedFilter
  );
}
