import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { config, IConfig } from './config';

const Container = styled.div<IConfig>`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};

  p {
    font-weight: 600;
    font-size: 18px;
    color: ${({ color }) => color};
  }
`;

const BodyOffset = createGlobalStyle`
  body {
    padding-top: 32px;
  }
`;

export const EnvBanner = () =>
  config ? (
    <Container {...config}>
      <BodyOffset />
      <p>{config.title}</p>
    </Container>
  ) : null;
