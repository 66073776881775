import { Box, CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  DateTimeInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { GameWeek } from 'ultimate-league-common';
import { fetchApi } from '~technical/api';

interface IFormProps {
  record?: GameWeek.IGameWeek;
}

const Form = ({ record, ...props }: IFormProps) => {
  const [previousGameWeek, setPreviousGameWeek] =
    useState<GameWeek.IGameWeek>();

  useEffect(() => {
    if (!record?.position) {
      return;
    }

    fetchApi(`/gameweek/${record.position - 1}`)
      .then((res) => res.json())
      .then(setPreviousGameWeek);
  }, [setPreviousGameWeek, record?.position]);

  if (!previousGameWeek) {
    return (
      <Box margin="auto">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <SimpleForm record={record} {...props}>
      <NumberInput source="position" disabled />
      <TextInput source="title" />
      <DateTimeInput
        source="startDate"
        inputProps={{
          min: previousGameWeek.endDate.slice(0, 16),
          step: 3600,
        }}
      />
      <DateTimeInput
        source="endDate"
        inputProps={{
          min: previousGameWeek.endDate.slice(0, 16),
          step: 3600,
        }}
      />
    </SimpleForm>
  );
};

export const GameWeekEdit = (props: {}) => (
  <Edit {...props}>
    <Form />
  </Edit>
);
