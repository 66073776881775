import React, { KeyboardEventHandler, PropsWithChildren } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from 'react-admin';
import { Blockchain, SoccerData } from 'ultimate-league-common';
import { AthleteField } from '~technical/filters/athlete';
import { QuickFilter } from '~ui/QuickFilter';

interface IAthlete {
  firstName: string;
  lastName: string;
  matchName: string;
}

const NftCardStatsFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <QuickFilter
        source={
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.FUNGIBLE
          ]
        }
        label="Batch failed to mint"
        defaultValue={0}
      />
      <ReferenceInput
        label="Batch"
        source="batchId"
        reference="nftbatch"
        filterToQuery={(search: string) => ({ label: search, isLive: true })}
      >
        <AutocompleteInput optionText="label" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput
        label="Athlete"
        source="athleteId"
        reference="athlete"
        filterToQuery={(search: string) => ({ [AthleteField.JOKER]: search })}
      >
        <AutocompleteInput
          optionText="matchName"
          optionValue="id"
          matchSuggestion={(filter: string, choice: Partial<IAthlete>) =>
            !filter ||
            choice.firstName?.includes(filter) ||
            choice.lastName?.includes(filter) ||
            choice.matchName?.includes(filter)
          }
        />
      </ReferenceInput>
      <ReferenceInput
        label="Club"
        source="clubId"
        reference="team"
        filterToQuery={(search: string) => ({
          name: search,
          type: SoccerData.Team.TeamType.CLUB,
        })}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      {children}
    </Filter>
  );
};

export const NftCardStatsList = (props: {}) => (
  <List {...props} filters={<NftCardStatsFilter />} bulkActionButtons={false}>
    <Datagrid>
      <ReferenceField
        label="Season"
        source="seasonId"
        reference="nftcardseason"
      >
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Batch" source="batchId" reference="nftbatch">
        <TextField source="label" />
      </ReferenceField>
      <ReferenceField label="Athlete" source="athleteId" reference="athlete">
        <TextField source="matchName" />
      </ReferenceField>
      <ReferenceField label="Club" source="clubId" reference="team">
        <TextField source="name" />
      </ReferenceField>
      <TextField
        label="Fungible"
        source={
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.FUNGIBLE
          ]
        }
      />
      <TextField
        label="_ (minted)"
        source={`${
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.FUNGIBLE
          ]
        } MINTED`}
      />
      <TextField
        label="Rare"
        source={
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.RARITY_4
          ]
        }
      />
      <TextField
        label="_ (minted)"
        source={`${
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.RARITY_4
          ]
        } MINTED`}
      />
      <TextField
        label="Epic"
        source={
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.RARITY_3
          ]
        }
      />
      <TextField
        label="_ (minted)"
        source={`${
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.RARITY_3
          ]
        } MINTED`}
      />
      <TextField
        label="Mythic"
        source={
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.RARITY_2
          ]
        }
      />
      <TextField
        label="_ (minted)"
        source={`${
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.RARITY_2
          ]
        } MINTED`}
      />
      <TextField
        label="Unique"
        source={
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.RARITY_1
          ]
        }
      />
      <TextField
        label="_ (minted)"
        source={`${
          Blockchain.NFTCard.RarityLevel[
            Blockchain.NFTCard.RarityLevel.RARITY_1
          ]
        } MINTED`}
      />
    </Datagrid>
  </List>
);
