import React, { KeyboardEventHandler, PropsWithChildren } from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { WalletField } from '~business/wallet/WalletField';
import { UserField } from '~technical/filters/user';

const UserFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* TODO: fix types */
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <TextInput source={UserField.USERNAME} label="Username" />
      {children}
    </Filter>
  );
};

export const UserList = (props: {}) => (
  <List
    {...props}
    filters={<UserFilter />}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="username" />
      <ReferenceField source="wallet" reference="wallet" link={false}>
        <WalletField short />
      </ReferenceField>
      <TextField source="role" />
      <EditButton {...props} />
    </Datagrid>
  </List>
);
