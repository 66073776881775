import { Button, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import {
  FormTab,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import { Blockchain } from 'ultimate-league-common';
import { athletePositions } from '~business/athlete/common';
import { IInputRaritySupply } from '~business/common/assetTypes';
import {
  processRarities,
  validateRaritySupply,
} from '~business/common/assetsLogic';
import { buildQuery, fetchApi } from '~technical/api';

import { raritySuppliesToObject } from './common';
import AthletesSelector from './components/AthletesSelector';
import RarityInputs from './components/RarityInputs';
import { useDeleteBatch } from './hooks';
import { IInputNftBatch, ISavedNftSeason } from './types';

const useStyles = makeStyles({
  toolbar: {
    '& button': {
      marginRight: '1.5em',
    },
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      width: '256px',
    },
  },
  hidden: {
    display: 'none',
  },
  rarities: {
    width: 'auto',
    '& > div': {
      marginBottom: '30px',
      '&:nth-child(odd)': {
        backgroundColor: '#efefef',
      },
    },
  },
  cardTemplate: {
    width: '100%',
  },
  errorMessage: {
    margin: '0.5em 0',
    color: 'red',
  },
});

const FormToolbar = (props: any) => {
  const { record, pristine } = props;
  const classes = useStyles();
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton disabled={pristine} />
      <Button
        color="secondary"
        variant="outlined"
        startIcon={<DeleteIcon />}
        disabled={!record?.id}
        onClick={() => {
          props.onDelete(record.id);
        }}
      >
        Delete
      </Button>
    </Toolbar>
  );
};

const validateValues = (values: IInputNftBatch) => {
  const errors: any = {};

  if (!values.label) {
    errors.label = 'Please enter a label';
  }
  if (values.rarities) {
    errors.rarities = {};
    Object.keys(values.rarities).forEach((rarityKey) => {
      errors.rarities[rarityKey] = validateRaritySupply(
        values.rarities[rarityKey],
        false
      );
      if (!errors.rarities[rarityKey]) {
        delete errors.rarities[rarityKey];
      }
    });
  }
  return errors;
};

const getBlankAssetsPerPosition = () =>
  athletePositions.map((position) => ({
    position,
    cardAssets: [],
  }));

const completeRarities = (
  rarityKeys: string[],
  rarities: IInputRaritySupply[]
) => {
  const completedRarities: IInputRaritySupply[] = [];

  rarityKeys.forEach((rarityKey) => {
    const match = rarities?.find(
      (r) => r.level === Blockchain.NFTCard.RarityLevel[rarityKey]
    );
    if (!match) {
      completedRarities.push({
        level: Blockchain.NFTCard.RarityLevel[rarityKey],
        supply: undefined,
        assetsPerPosition: getBlankAssetsPerPosition(),
      });
    } else if (
      Array.isArray(match.assetsPerPosition) !== true ||
      match.assetsPerPosition.length === 0
    ) {
      completedRarities.push({
        ...match,
        assetsPerPosition: getBlankAssetsPerPosition(),
      });
    } else {
      completedRarities.push(match);
    }
  });
  return completedRarities;
};

const getInitialValues = (
  { rarities, ...restBatch }: IInputNftBatch,
  rarityKeys: string[]
) => ({
  ...restBatch,
  rarities: raritySuppliesToObject(
    rarityKeys,
    completeRarities(rarityKeys, rarities)
  ),
});

export const NftBatchForm = (props: any) => {
  const { record } = props;
  const classes = useStyles();
  const deleteBatch = useDeleteBatch();
  const [seasonId, setSeasonId] = useState<string | undefined>(record.season);
  const [seasonRarities, setSeasonRarities] = useState<string[]>([]);
  const [initialValues, setInitialValues] = useState(
    getInitialValues(record, seasonRarities)
  );

  useEffect(() => {
    (async () => {
      if (!seasonId) {
        setSeasonRarities([]);
      } else {
        const response = await fetchApi(
          `/backoffice/nftcardseason/${seasonId}?${buildQuery({
            select: 'rarities',
          })}`
        );
        const season = (await response.json()) as ISavedNftSeason;
        const seasonRarityValues: Blockchain.NFTCard.RarityLevel[] =
          season?.rarities?.map(({ level }) => level) || [];
        seasonRarityValues.sort((l1, l2) => l1 - l2);
        const seasonRarityKeys: string[] = seasonRarityValues.map(
          (level) => Blockchain.NFTCard.RarityLevel[level]
        );
        setSeasonRarities(seasonRarityKeys);
      }
    })();
  }, [record, seasonId]);

  useEffect(() => {
    setInitialValues(getInitialValues(record, seasonRarities));
  }, [record, seasonRarities]);

  const onSeasonSelect = (event: BaseSyntheticEvent) => {
    setSeasonId(event.target.value);
  };

  const handleSave = async (
    { rarities, ...values }: IInputNftBatch,
    redirect: string,
    ...params: any[]
  ) =>
    props.save(
      {
        ...values,
        rarities: await processRarities(Object.values(rarities)),
      },
      redirect,
      ...params
    );

  return (
    <TabbedForm
      {...props}
      record={record.id ? initialValues : record}
      initialValues={initialValues}
      validate={validateValues}
      save={handleSave}
      toolbar={<FormToolbar onDelete={deleteBatch} />}
    >
      <FormTab label="Batch properties">
        <TextInput source="label" label="Batch label" />
        <ReferenceInput
          source="season"
          reference="nftcardseason"
          onChange={onSeasonSelect}
          filter={{ isLive: true }}
        >
          <SelectInput optionText="title" optionValue="id" />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Override card template">
        <TextInput
          multiline
          resettable
          className={classes.cardTemplate}
          source="cardTemplate"
        />
      </FormTab>
      <FormTab label="Override rarity assets">
        {!seasonId && (
          <Typography variant="subtitle1" className={classes.errorMessage}>
            You must select a season first.
          </Typography>
        )}
        <div className={classes.rarities}>
          {seasonRarities.map((rarityKey) => (
            <RarityInputs key={rarityKey} rarityKey={rarityKey} />
          ))}
        </div>
      </FormTab>
      <FormTab label="Select athletes">
        <AthletesSelector />
      </FormTab>
    </TabbedForm>
  );
};

export default NftBatchForm;
