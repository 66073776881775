"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTeams = void 0;
var technical_1 = require("../../technical");
var type_1 = require("./type");
exports.getTeams = {
    q: {
        type: 'string',
        length: {
            minimum: 3,
            maximum: 30,
        },
        presence: {
            allowEmpty: false,
        },
    },
    type: {
        inclusion: technical_1.enumToArray(type_1.TeamType),
    },
};
