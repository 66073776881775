"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompetitionFormat = exports.CompetitionType = void 0;
var CompetitionType;
(function (CompetitionType) {
    CompetitionType["CLUB"] = "club";
    CompetitionType["INTERNATIONAL"] = "international";
})(CompetitionType = exports.CompetitionType || (exports.CompetitionType = {}));
var CompetitionFormat;
(function (CompetitionFormat) {
    CompetitionFormat["DOMESTIC_LEAGUE"] = "Domestic league";
    CompetitionFormat["DOMESTIC_CUP"] = "Domestic cup";
    CompetitionFormat["DOMESTIC_SUPER_CUP"] = "Domestic super cup";
    CompetitionFormat["INTERNATIONAL_CUP"] = "International cup";
    CompetitionFormat["INTERNATIONAL_SUPER_CUP"] = "International super cup";
})(CompetitionFormat = exports.CompetitionFormat || (exports.CompetitionFormat = {}));
