"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enumToArray = void 0;
/**
 * Convert an Enumeration to a Array<string|number>
 *
 * @param enumeration Enumeration to convert
 * @param ofString Convert Enumeration to a string Array (or number Array otherwise)
 *
 * @returns Array<any>
 */
function enumToArray(enumeration, ofString) {
    if (ofString === void 0) { ofString = true; }
    return Object.keys(enumeration)
        .map(function (key) { return enumeration[key]; })
        .filter(function (value) {
        return ofString ? typeof value === 'string' : typeof value === 'number';
    });
}
exports.enumToArray = enumToArray;
