import { alpha, styled as muiStyled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrayInput } from 'react-admin';

export const StyledArrayInput = muiStyled(ArrayInput)({
  '': {
    width: 'auto',
  },
});

export const useStyles = makeStyles((theme) => ({
  removeRarityButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
    },
    margin: '8px 24px',
  },
}));
