import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import React, { useCallback, useEffect, useState } from 'react';
import { useCreate, useNotify } from 'react-admin';
import { EnvFlag } from '~business/env-warnings/EnvFlag';
import { fetchApi } from '~technical/api';

export const BackupImport = () => {
  const [create] = useCreate('backup');
  const notify = useNotify();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [remoteBackups, setRemoteBackups] = useState<string[]>([]);
  const [selectedBackup, setSelectedBackup] = useState<string>();

  const showDialog = useCallback(() => {
    setDialogVisible(true);
  }, [setDialogVisible]);

  const closeDialog = useCallback(() => {
    setDialogVisible(false);
  }, [setDialogVisible]);

  useEffect(() => {
    setFetching(true);
    (async () => {
      const res = await fetchApi('/backoffice/backup/gcs');
      const { backups } = await res.json();
      setRemoteBackups(backups);
      setFetching(false);
    })();
  }, [setRemoteBackups, setFetching]);

  const importBackup = useCallback(() => {
    create(
      {
        payload: {
          data: {
            filename: selectedBackup,
            description: 'Imported from GCS',
            strategy: 'gcs',
            ready: true,
          },
        },
      },
      {
        onSuccess: () => {
          notify('Backup imported', 'info');
          closeDialog();
        },
        onFailure: () => {
          notify('Backup importation error', 'error');
        },
      }
    );
  }, [selectedBackup, create, notify, closeDialog]);

  return (
    <>
      <Dialog open={dialogVisible} onClose={closeDialog}>
        <DialogTitle>
          <EnvFlag /> Import a backup from GCS
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the backup you want to import:
            {fetching ? (
              <CircularProgress style={{ margin: 24 }} />
            ) : (
              <Select
                label="Backup"
                value={selectedBackup}
                onChange={(e) => setSelectedBackup(e.target.value as string)}
              >
                {remoteBackups.map((filename) => (
                  <MenuItem key={filename} value={filename}>
                    {filename}
                  </MenuItem>
                ))}
              </Select>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} autoFocus>
            Cancel
          </Button>
          {selectedBackup && (
            <Button onClick={importBackup} color="primary" variant="contained">
              Import backup
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Button
        color="primary"
        onClick={showDialog}
        size="small"
        startIcon={<CloudDownloadIcon />}
      >
        Import from GCS
      </Button>
    </>
  );
};
