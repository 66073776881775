import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

export const BackupCreate = (props: {}) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="filename"
        initialValue="backup_{CURRENT_DATE}"
        disabled
      />
      <TextInput source="description" multiline />
    </SimpleForm>
  </Create>
);
