import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
} from '@material-ui/core';
import React from 'react';
import { FilterPayload, TextInput } from 'react-admin';
import { Field, Form } from 'react-final-form';

import { KeyValueObject } from './interfaces';

interface ISaveFiltersDialogProps {
  saveFiltersDialogVisibility: boolean;
  submitFilters: (formValues: KeyValueObject) => void;
  setSaveFiltersDialogVisibility: (visibilityStatus: boolean) => void;
  filtersConfig?: FilterPayload;
}

const SaveFiltersDialog = (props: ISaveFiltersDialogProps) => {
  const saveFilters = (formValues: KeyValueObject) => {
    props.submitFilters(formValues);
    props.setSaveFiltersDialogVisibility(false);
  };

  const cancel = () => {
    props.setSaveFiltersDialogVisibility(false);
  };

  return (
    <Form onSubmit={saveFilters}>
      {(form) => (
        <Dialog
          open={props.saveFiltersDialogVisibility}
          onClose={() => {
            props.setSaveFiltersDialogVisibility(false);
          }}
        >
          <DialogTitle>Save Filters</DialogTitle>
          <DialogContent style={{ minWidth: 300 }}>
            <InputLabel htmlFor="reference">Reference</InputLabel>
            <Field name="reference" type="text">
              {({ input }) => (
                /* @ts-ignore */
                <TextInput
                  fullWidth
                  source="reference"
                  style={{ margin: 8 }}
                  {...input}
                />
              )}
            </Field>
          </DialogContent>
          <DialogActions>
            {form.submitting ? (
              <CircularProgress style={{ margin: 12 }} />
            ) : (
              <>
                <Button onClick={cancel} color="secondary">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={form.handleSubmit}
                >
                  Submit
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};

export { SaveFiltersDialog };
