import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { Blockchain } from 'ultimate-league-common';

import { IDraftCard, IRecordNFT } from '../../interfaces';
import { mintNFT } from '../../service';

interface IGenerateCardButtonProps {
  card: IRecordNFT;
  onClick: () => void;
}

const GenerateCardButton = ({ card, onClick }: IGenerateCardButtonProps) => {
  if (card.mintStatus) {
    return (
      <Button disabled>
        {card.mintStatus}
        ...
      </Button>
    );
  }
  if (card.level !== Blockchain.NFTCard.RarityLevel.FUNGIBLE) {
    return (
      <Button color="primary" onClick={onClick}>
        Generate card
      </Button>
    );
  }
  return null;
};

interface IGenerateCardConfirmButtonProps {
  card: IDraftCard;
}

export const GenerateCardConfirmButton = ({
  card,
}: IGenerateCardConfirmButtonProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [dialogVisible, setDialogVisible] = useState(false);
  const showDialog = useCallback(() => {
    setDialogVisible(true);
  }, [setDialogVisible]);
  const closeDialog = useCallback(() => {
    setDialogVisible(false);
  }, [setDialogVisible]);

  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = useCallback(async () => {
    setSubmitting(true);

    try {
      await mintNFT(card.id);
      await dataProvider.getOne<IRecordNFT>('NftCard', card);
      closeDialog();
      notify(`Minting NFT ${card.recipe.metadata.title}...`, 'info');
    } catch (e) {
      notify('Could not launch mint', 'error');
    } finally {
      setSubmitting(false);
    }
  }, [card, notify, dataProvider, setSubmitting, closeDialog]);

  useEffect(() => {
    if (!card.mintStatus) {
      return () => {};
    }

    const ROUTINE_LOOP_MS = 2000;
    let stopped = false;
    let timeout: ReturnType<typeof setTimeout>;

    const checkMinted = async () => {
      const { data } = await dataProvider.getOne<IRecordNFT>('NftCard', card);
      if (stopped) {
        return;
      }

      if (data.mint) {
        notify(
          `NFT ${card.recipe.metadata.title} successfully minted !`,
          'info'
        );
      } else {
        timeout = setTimeout(checkMinted, ROUTINE_LOOP_MS);
      }
    };

    timeout = setTimeout(checkMinted, ROUTINE_LOOP_MS);

    return () => {
      clearTimeout(timeout);
      stopped = true;
    };
  }, [dataProvider, card, notify]);

  return (
    <>
      <Dialog open={dialogVisible} onClose={closeDialog}>
        <DialogTitle>
          Are you sure you want to generate card{' '}
          <b>{card.recipe.metadata.title}</b> ?
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            This action cannot be cancelled. Once this action is done, nothing
            can stop or revert it.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {submitting ? (
            <CircularProgress style={{ margin: 24 }} />
          ) : (
            <>
              <Button
                onClick={closeDialog}
                color="primary"
                variant="contained"
                autoFocus
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="secondary">
                Proceed
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <GenerateCardButton card={card} onClick={showDialog} />
    </>
  );
};
