"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.t = exports.usePlatform = exports.getLang = exports.useLang = exports.LANG = exports.PLATFORM = void 0;
var enWeb = __importStar(require("./en-web.json"));
var frWeb = __importStar(require("./fr-web.json"));
var PLATFORM;
(function (PLATFORM) {
    PLATFORM["WEB"] = "web";
})(PLATFORM = exports.PLATFORM || (exports.PLATFORM = {}));
var LANG;
(function (LANG) {
    LANG["EN"] = "en";
    LANG["FR"] = "fr";
})(LANG = exports.LANG || (exports.LANG = {}));
var MAPPING = (_a = {},
    _a[PLATFORM.WEB] = (_b = {},
        _b[LANG.EN] = enWeb,
        _b[LANG.FR] = frWeb,
        _b),
    _a);
var currentLang = LANG.EN;
var currentPlatform = PLATFORM.WEB;
function useLang(lang) {
    currentLang = lang;
}
exports.useLang = useLang;
function getLang() {
    return currentLang;
}
exports.getLang = getLang;
function usePlatform(platform) {
    currentPlatform = platform;
}
exports.usePlatform = usePlatform;
function t(key) {
    var translation = MAPPING[currentPlatform][currentLang][key];
    if (!translation) {
        return "{" + key + "}";
    }
    return translation;
}
exports.t = t;
