import { Link } from '@material-ui/core';
import React from 'react';
import { BooleanField, TextField } from 'react-admin';
import { Blockchain } from 'ultimate-league-common';

const { BSC_SCAN_URL } = process.env;
if (!BSC_SCAN_URL) {
  throw new Error('Missing env BSC_SCAN_URL');
}

interface IProps {
  record?: {
    id: string;
    address?: string;
  };
  short?: boolean;
}

export const WalletField = ({ record, short, ...props }: IProps) => {
  const walletAddress =
    record?.address && Blockchain.withPrefix(record.address);

  return walletAddress ? (
    <Link
      href={`${BSC_SCAN_URL}/address/${walletAddress}`}
      target="_blank"
      rel="noreferrer"
    >
      <TextField
        source="address"
        record={{
          id: record!.id,
          address: short
            ? `${walletAddress.slice(0, 6)}......${walletAddress.slice(-6)}`
            : walletAddress,
        }}
        {...props}
      />
    </Link>
  ) : (
    <BooleanField
      source="address"
      record={{ id: record!.id, address: false }}
      {...props}
    />
  );
};
