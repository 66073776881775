"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RarityLevel = void 0;
var RarityLevel;
(function (RarityLevel) {
    RarityLevel[RarityLevel["FUNGIBLE"] = 0] = "FUNGIBLE";
    RarityLevel[RarityLevel["RARITY_4"] = 40] = "RARITY_4";
    RarityLevel[RarityLevel["RARITY_3"] = 60] = "RARITY_3";
    RarityLevel[RarityLevel["RARITY_2"] = 80] = "RARITY_2";
    RarityLevel[RarityLevel["RARITY_1"] = 100] = "RARITY_1";
})(RarityLevel = exports.RarityLevel || (exports.RarityLevel = {}));
