import { Box, MenuItem, Select } from '@material-ui/core';
import Handlebars from 'handlebars';
import React, {
  BaseSyntheticEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { linkToRecord } from 'react-admin';
import { Link } from 'react-router-dom';
import { IAthlete } from '~business/athlete/types';
import { ISavedRaritySupply } from '~business/common/assetTypes';
import { ISavedNftBatch, ISavedNftSeason } from '~business/nft-batch/types';

import { getAssetsWithUrl, getAssetUrl } from './asset-helpers';
import { EditLinks, Preview, Wrapper } from './athlete-preview.styles';
import { getFontsWithDataUrl } from './font-helpers';
import { prepareIngredients } from './preview-helpers';
import { InputWrapper, StyledInputLabel } from './styles';

/* eslint-disable  no-underscore-dangle */

const getAthleteById = (id: string, athletes: IAthlete[]) =>
  athletes.find((a) => a._id === id);

interface IProps {
  index: number;
  batch: ISavedNftBatch;
  season: ISavedNftSeason;
  athletes: IAthlete[];
  compiledTemplate: Handlebars.TemplateDelegate<any>;
  edition: number;
  scale: number;
  raritySelected: ISavedRaritySupply;
}

export const AthletePreview = ({
  index,
  batch,
  season,
  athletes,
  compiledTemplate,
  edition,
  scale,
  raritySelected,
}: IProps) => {
  const previewRef = useRef<HTMLDivElement | null>(null);
  const [html, setHtml] = useState<string>();
  const [error, setError] = useState<string>();
  const [athleteSelectedId, setAthleteSelectedId] = useState<string>(
    athletes[index]._id
  );
  const [athleteSelected, setAthleteSelected] = useState<IAthlete>();

  const executeScript = () => {
    const previewEl = previewRef.current;
    if (!previewEl) {
      return;
    }
    const scriptsEls = previewEl.querySelectorAll('script');
    scriptsEls.forEach((scriptEl) => {
      // eslint-disable-next-line no-eval
      window.eval(scriptEl.innerText);
    });
  };

  useEffect(() => {
    const athlete = getAthleteById(athleteSelectedId, athletes);
    setAthleteSelected(athlete);
  }, [athleteSelectedId, athletes]);

  useEffect(() => {
    (async () => {
      if (
        !compiledTemplate ||
        !edition ||
        !batch ||
        !season ||
        !athleteSelected ||
        !raritySelected
      ) {
        setHtml(undefined);
      } else {
        try {
          // 1 - Prepare ingredients in a generic manner, like `server_tools` receive them.
          const { ingredients, assetIds } = await prepareIngredients(
            edition,
            athleteSelected,
            season,
            raritySelected
          );

          // 2 - Assets must be parsed to be displayable
          // NB: Assets url are valid for a short time only, so tools and card preview
          // have to generate those urls before applying the template
          const [athleteAssetUrl, clubAssetUrl] = await Promise.all(
            [assetIds.athleteAssetId, assetIds.clubLogoAssetId].map(getAssetUrl)
          );

          if (!athleteAssetUrl) {
            throw new Error('Missing athlete card asset to build NFT image');
          }
          if (!clubAssetUrl) {
            throw new Error('Missing club logo to build NFT image');
          }

          const ingredientsWithAssetsUrl = {
            ...ingredients,
            athleteAsset: athleteAssetUrl,
            clubAsset: clubAssetUrl,
            assets: (await getAssetsWithUrl(assetIds.assets)).map(
              ({ url }) => url
            ),
            fonts: await getFontsWithDataUrl(season.cardFonts),
          };

          // 3 - Apply template
          setHtml(compiledTemplate(ingredientsWithAssetsUrl));

          setError(undefined);
        } catch (e: any) {
          setHtml(undefined);
          setError(`Data issue: ${e.message}`);
        }
      }
    })();
  }, [
    compiledTemplate,
    batch,
    season,
    edition,
    raritySelected,
    athleteSelected,
  ]);

  useLayoutEffect(() => {
    // React prevents <script> execution, so we need to trigger it manually
    executeScript();
  }, [html]);

  const onAthleteChange = (event: BaseSyntheticEvent) => {
    const newAthleteId = event.target.value;
    setAthleteSelectedId(newAthleteId);
  };

  return (
    <Wrapper scale={scale}>
      <InputWrapper>
        <StyledInputLabel id="select-athlete">Athlete</StyledInputLabel>
        <Select
          labelId="select-athlete"
          value={athleteSelectedId}
          label="Athlete"
          onChange={onAthleteChange}
        >
          {athletes.map((athlete) => (
            <MenuItem key={athlete._id} value={athlete._id}>
              {athlete.matchName}
            </MenuItem>
          ))}
        </Select>
      </InputWrapper>

      {athleteSelected && (
        <EditLinks>
          <span>Edit:</span>
          <Link
            to={linkToRecord('/athlete', athleteSelected._id, 'edit')}
            target="_blank"
          >
            athlete
          </Link>

          {athleteSelected.club && (
            <Link
              to={linkToRecord('/team', athleteSelected.club._id, 'edit')}
              target="blank_"
            >
              club
            </Link>
          )}

          {athleteSelected.national && (
            <Link
              to={linkToRecord('/team', athleteSelected.national._id, 'edit')}
              target="blank_"
            >
              national
            </Link>
          )}
        </EditLinks>
      )}
      {error && <Box margin="1em">{error}</Box>}
      {!error && !html && <Box margin="1em">No preview available</Box>}
      {html && (
        <Preview ref={previewRef} dangerouslySetInnerHTML={{ __html: html }} />
      )}
    </Wrapper>
  );
};
