import Jimp from 'jimp';
import React from 'react';
import {
  AutocompleteInput,
  DateInput,
  Edit,
  EditProps,
  FormTab,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TabbedFormProps,
  TextInput,
} from 'react-admin';
import {
  Blockchain,
  Database,
  SoccerData,
  Storage,
} from 'ultimate-league-common';
import {
  footChoices,
  positionChoices,
  rarityAssetsToObject,
  rarityKeys,
  tierChoices,
} from '~business/athlete/common';
import { IAsset } from '~business/common/assetTypes';
import { IRarityFile } from '~business/nft-batch/common';
import { StorageImageInput } from '~business/storage/StorageImageInput';
import { uploadToStorage } from '~business/storage/service';

import { IAthlete } from './types';

const convertImage = (file: File) =>
  new Promise<Buffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      const img = await Jimp.read(reader.result as any);
      resolve(
        img.quality(70).resize(32, Jimp.AUTO).getBufferAsync(Jimp.MIME_PNG)
      );
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });

const RarityAssets = (props: any) => (
  <div>
    {rarityKeys.map((rarityKey) => (
      <StorageImageInput
        {...props}
        key={rarityKey}
        source={`assets.rarities.${rarityKey}`}
        label={rarityKey}
      />
    ))}
  </div>
);

interface IAssets {
  main?: { small?: string | null; medium?: IAsset };
  rarities: IRarityFile;
}

const AthleteForm = (props: any) => {
  const { record: athlete } = props as { record: IAthlete };

  const processedAthlete = {
    ...athlete,
    assets: {
      main: athlete.assets?.main,
      rarities: rarityAssetsToObject(rarityKeys, athlete.assets?.rarities),
    },
  };

  const handleSave: TabbedFormProps['save'] = async (
    values,
    redirect,
    ...params: any[]
  ) => {
    const { id, assets, club, national } = values as {
      id: string;
      assets: IAssets;
      club: Database.DocumentId;
      national: Database.DocumentId;
    };

    let redirectTo: string = '';
    if (club || national) {
      redirectTo = `/athlete/?displayedFilters=${[
        '{"team":true}',
        `filter={"team":"${club || national}"}`,
        'order=ASC',
        'page=1',
        'perPage=25',
        'sort=matchName',
      ].join('&')}`;
    }

    const processedAssets: IAthlete['assets'] = {
      main: await (async () => {
        if (!assets?.main?.medium) {
          return {
            small: null,
            medium: null,
          };
        }
        if (typeof assets.main.medium === 'string') {
          return {
            small: assets.main.small!,
            medium: assets.main.medium,
          };
        }
        return {
          small: await uploadToStorage(
            {
              type: Storage.StorageType.PUBLIC_ATHLETE_MAIN,
              athleteId: id,
              format: Storage.AssetFormat.SMALL,
            },
            await convertImage(assets.main.medium.rawFile)
          ),
          medium: await uploadToStorage(
            {
              type: Storage.StorageType.PUBLIC_ATHLETE_MAIN,
              athleteId: id,
              format: Storage.AssetFormat.MEDIUM,
            },
            assets.main.medium.rawFile
          ),
        };
      })(),
      rarities: await Promise.all(
        Object.keys(assets.rarities!).map((rarityKey) =>
          (async () => {
            const asset = assets.rarities![rarityKey];
            let file: string | null = null;

            if (typeof asset === 'string') {
              // case of an unmodified existing asset
              file = asset;
            } else if (asset?.rawFile) {
              // case of a new file
              file = await uploadToStorage(
                {
                  type: Storage.StorageType.ATHLETE_CARD_ASSET,
                  athleteId: id,
                  rarity: rarityKey,
                },
                asset.rawFile
              );
            }
            return {
              rarity: Blockchain.NFTCard.RarityLevel[rarityKey],
              file,
            };
          })()
        )
      ),
    };
    return props.save(
      {
        ...values,
        assets: processedAssets,
      },
      redirectTo,
      ...params
    );
  };

  return (
    <TabbedForm {...props} record={processedAthlete} save={handleSave}>
      <FormTab label="summary">
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="matchName" />
        <SelectInput source="position" choices={positionChoices} />
        <ReferenceInput
          source="club"
          reference="team"
          filterToQuery={(search: string) => ({
            name: search,
            type: SoccerData.Team.TeamType.CLUB,
          })}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          source="national"
          reference="team"
          filterToQuery={(search: string) => ({
            name: search,
            type: SoccerData.Team.TeamType.NATIONAL,
          })}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <StorageImageInput source="assets.main.medium" label="Main (512x512)" />
      </FormTab>

      <FormTab label="Card assets">
        <RarityAssets />
      </FormTab>

      <FormTab label="refs">
        <TextInput disabled source="optaId" />
      </FormTab>

      <FormTab label="data">
        <NumberInput source="shirtNumber" />
        <SelectInput source="tier" choices={tierChoices} />
        <DateInput source="birth" />
        <NumberInput source="height" />
        <NumberInput source="weight" />
        <SelectInput source="foot" choices={footChoices} />
      </FormTab>
    </TabbedForm>
  );
};

export const AthleteEdit = (props: EditProps) => (
  <Edit {...props}>
    {/* @ts-ignore */}
    <AthleteForm />
  </Edit>
);
