import React from 'react';
import {
  Edit,
  EditProps,
  SelectInput,
  SimpleForm,
  TextInput,
  TextInputProps,
  TranslatableInputs,
} from 'react-admin';
import { useField } from 'react-final-form';
import { Blockchain, enumToArray, LANG, t } from 'ultimate-league-common';

import IconCommon from './rarity-icons/rarity-common.svg';
import IconEpic from './rarity-icons/rarity-epic.svg';
import IconMythic from './rarity-icons/rarity-mythic.svg';
import IconRare from './rarity-icons/rarity-rare.svg';
import IconUnique from './rarity-icons/rarity-unique.svg';

const Icons: Record<
  Blockchain.NFTCard.RarityLevel,
  React.ComponentClass<React.SVGProps<SVGSVGElement>>
> = {
  [Blockchain.NFTCard.RarityLevel.FUNGIBLE]: IconCommon,
  [Blockchain.NFTCard.RarityLevel.RARITY_4]: IconRare,
  [Blockchain.NFTCard.RarityLevel.RARITY_3]: IconEpic,
  [Blockchain.NFTCard.RarityLevel.RARITY_2]: IconMythic,
  [Blockchain.NFTCard.RarityLevel.RARITY_1]: IconUnique,
};

const I18NKeys: Record<Blockchain.NFTCard.RarityLevel, string> = {
  [Blockchain.NFTCard.RarityLevel.FUNGIBLE]: 'RARITY_LEVEL_COMMON',
  [Blockchain.NFTCard.RarityLevel.RARITY_4]: 'RARITY_LEVEL_RARE',
  [Blockchain.NFTCard.RarityLevel.RARITY_3]: 'RARITY_LEVEL_EPIC',
  [Blockchain.NFTCard.RarityLevel.RARITY_2]: 'RARITY_LEVEL_MYTHIC',
  [Blockchain.NFTCard.RarityLevel.RARITY_1]: 'RARITY_LEVEL_UNIQUE',
};

const levelChoices = enumToArray(Blockchain.NFTCard.RarityLevel, false).map(
  (level) => ({
    id: level,
  })
);

function optionText({ id }: { id: Blockchain.NFTCard.RarityLevel }) {
  const Icon = Icons[id];
  return (
    <>
      <Icon height={14} style={{ marginRight: 6 }} />
      {t(Blockchain.NFTCard.RarityLevel[id])}
    </>
  );
}

const ShortDescription = (props: TextInputProps) => {
  const {
    input: { value: level },
  } = useField('level');
  const levelTranslation = t(I18NKeys[level]);
  const defaultShortDescription = t(
    'REWARD_ITEM_DEFAULT_SHORT_DESCRIPTION'
  ).replace('{{level}}', levelTranslation);

  return (
    <TranslatableInputs {...props} locales={enumToArray(LANG)}>
      <TextInput
        source="shortDescription"
        helperText={`Default to "${defaultShortDescription}"`}
      />
    </TranslatableInputs>
  );
};

export const FilterEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="reference" />
      <SelectInput
        label="Level"
        source="query.level"
        choices={levelChoices}
        optionText={optionText}
        translateChoice={false}
      />
      {
        // @ts-ignore
        <ShortDescription />
      }
    </SimpleForm>
  </Edit>
);
