import { Button, CircularProgress } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import * as React from 'react';
import { useCallback, useState } from 'react';
import {
  Datagrid,
  DateField,
  downloadCSV,
  List,
  ReferenceField,
  TextField,
  useNotify,
} from 'react-admin';
import { buildQuery, fetchApi } from '~technical/api';
import { error } from '~technical/logger';
import { PresenceField } from '~ui/PresenceField';

interface IBulkActionProps {
  selectedIds: string[];
}

const DownloadCSV = ({ selectedIds }: IBulkActionProps) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const downloadStats = useCallback(async () => {
    setLoading(true);
    const response = await fetchApi(
      `/backoffice/match-stats?${buildQuery({ matches: selectedIds })}`
    );
    try {
      downloadCSV((await response.json()).CSV, 'match-stats');
    } catch (e) {
      error(e);
      notify('An error occurred while generating the CSV. Aborting.', 'error');
    }

    setLoading(false);
  }, [selectedIds, setLoading, notify]);

  return (
    <Button
      color="primary"
      variant="contained"
      startIcon={
        loading ? <CircularProgress color="inherit" size={20} /> : <GetApp />
      }
      disabled={loading}
      onClick={downloadStats}
    >
      Download Stats
    </Button>
  );
};

const MatchBulkActionButtons = (props: any) => (
  <>
    <DownloadCSV {...props} />
  </>
);

export const MatchList = (props: {}) => (
  <List
    sort={{ field: 'date', order: 'ASC' }}
    exporter={false}
    bulkActionButtons={<MatchBulkActionButtons />}
    {...props}
  >
    <Datagrid>
      <DateField source="date" />
      <ReferenceField source="homeContestant" reference="team" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="awayContestant" reference="team" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="gameWeek" reference="gameweek">
        <TextField source="position" />
      </ReferenceField>
      <PresenceField source="stat" />
    </Datagrid>
  </List>
);
