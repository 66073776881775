import { Card, Step, StepButton, Stepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { DateTimeInput, SimpleForm, TextInput } from 'react-admin';
import { Storage } from 'ultimate-league-common';
import { IInputRaritySupply } from '~business/common/assetTypes';
import {
  processRarities,
  validateRaritySupply,
} from '~business/common/assetsLogic';
import { uploadToStorage } from '~business/storage/service';

import { FontsInput } from './components/FontsInput';
import { RaritiesInput } from './components/RaritiesInput';
import { IFont, IRarity } from './types';

const FONT_PATH_REGEXP = /\.(ttf|woff|woff2|otf)$/;

const isFontFile = (file: File) => FONT_PATH_REGEXP.test(file.name);

const getFontExtension = (file: File) => {
  const result = FONT_PATH_REGEXP.exec(file.name);
  return !result ? null : result[1];
};

const steps = [
  'Define season configuration',
  'Define rarity',
  'Set fonts',
  'Define card template',
];

interface IInputSeason {
  title?: string;
  startDate?: Date;
  endDate?: Date;
  rarities?: IInputRaritySupply[];
  cardFonts?: IFont[];
}

const validateForm = (season: IInputSeason) => {
  const errors: any = {};
  if (!season.title?.trim()) {
    errors.title = 'Missing title';
  }
  if (
    !season.startDate ||
    (season.startDate instanceof Date &&
      Number.isNaN(season.startDate.getTime()))
  ) {
    errors.startDate = 'Missing start date';
  }
  if (
    !season.endDate ||
    (season.endDate instanceof Date && Number.isNaN(season.endDate.getTime()))
  ) {
    errors.endDate = 'Missing end date';
  }

  if (
    Array.isArray(season.rarities) !== true ||
    season.rarities!.length === 0
  ) {
    errors.rarities = 'You must define at least one rarity';
  } else {
    errors.rarities = season.rarities!.map((rarity: IInputRaritySupply) =>
      validateRaritySupply(rarity, true)
    );
  }

  if (Array.isArray(season.cardFonts) === true) {
    errors.cardFonts = season.cardFonts!.map(
      ({ fontFamily, fontStyle, fontWeight, font }) => {
        const fontErrors: any = {};

        if (!fontFamily) {
          fontErrors.fontFamily = 'Font family is required';
        }
        if (!fontStyle) {
          fontErrors.fontStyle = 'Font style is required';
        }
        if (!fontWeight) {
          fontErrors.fontWeight = 'Font weight is required';
        }
        if (!font) {
          fontErrors.font = 'Font file is required';
        } else if (
          typeof font !== 'string' &&
          font.rawFile &&
          !isFontFile(font.rawFile)
        ) {
          fontErrors.font = 'Selected file should be a font';
        }
        return fontErrors;
      }
    );
  }
  return errors;
};

const useStyles = makeStyles({
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      width: '256px',
    },
  },
  hidden: {
    display: 'none',
  },
  cardTemplate: {
    width: '100%',
  },
});

interface ISeasonFormContentProps {
  step: number;
  onStep: (step: number) => void;
}

const SeasonFormContent = ({ step, onStep }: ISeasonFormContentProps) => {
  const classes = useStyles();
  return (
    <>
      <Card variant="outlined">
        <Stepper nonLinear activeStep={step}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton onClick={() => onStep(index)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
      </Card>
      <div
        className={`${classes.inputsContainer} ${
          step === 0 ? undefined : classes.hidden
        }`}
      >
        <TextInput source="title" />
        <DateTimeInput source="startDate" />
        <DateTimeInput source="endDate" />
      </div>
      <div className={step === 1 ? undefined : classes.hidden}>
        <RaritiesInput />
      </div>
      <div className={step === 2 ? undefined : classes.hidden}>
        <FontsInput />
      </div>
      <div className={step === 3 ? undefined : classes.hidden}>
        <TextInput
          multiline
          resettable
          className={classes.cardTemplate}
          source="cardTemplate"
        />
      </div>
    </>
  );
};

export const SeasonForm = ({ record, ...props }: any) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  const handleSave = async (
    values: { rarities: IRarity[]; cardFonts: IFont[] },
    redirect: string,
    ...params: any[]
  ) => {
    const processedValues = {
      ...values,
      rarities: await processRarities(values.rarities),
      cardFonts:
        Array.isArray(values.cardFonts) !== true
          ? null
          : await Promise.all(
              values.cardFonts?.map(async (data) => {
                if (typeof data.font === 'string') {
                  return data;
                }
                if (data.font?.rawFile) {
                  let mediaType = data.font.rawFile.type;
                  if (!mediaType) {
                    // Fallback: on Windows the media type is not provided for fonts...
                    const fontExtension = getFontExtension(data.font.rawFile);
                    if (!fontExtension) {
                      throw new Error(
                        `Cannot determine font extension for file ${data.font.rawFile.name}`
                      );
                    }
                    mediaType = `font/${fontExtension}`;
                  }

                  return {
                    ...data,
                    font: await uploadToStorage(
                      {
                        type: Storage.StorageType.TOOL,
                        fileName: data.font.rawFile.name,
                      },
                      data.font.rawFile
                    ),
                    mediaType,
                  };
                }
                return data;
              })
            ),
    };
    return props.save(processedValues, redirect, ...params);
  };

  return (
    <SimpleForm
      {...props}
      record={record}
      validate={validateForm}
      save={handleSave}
      initialValues={{ rarities: [] }}
    >
      {
        // @ts-ignore
        <SeasonFormContent fullWidth step={activeStep} onStep={handleStep} />
      }
    </SimpleForm>
  );
};
