import * as React from 'react';
import styled from 'styled-components';

import { config, IConfig } from './config';

const Container = styled.div<IConfig>`
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};

  p {
    font-weight: 600;
    font-size: 14px;
    color: ${({ color }) => color};
  }
`;

export const EnvFlag = () =>
  config ? (
    <Container {...config}>
      <p>{config.title}</p>
    </Container>
  ) : null;
