import Jimp from 'jimp';
import React from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  SimpleForm,
  SimpleFormProps,
  TextInput,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { Storage } from 'ultimate-league-common';
import { StorageImageInput } from '~business/storage/StorageImageInput';
import { uploadToStorage } from '~business/storage/service';

import { ITeam } from './types';

interface IValidationErrors {
  code?: string;
  shortName?: string;
}

function validate(team: ITeam) {
  const errors: IValidationErrors = {};
  if (!team.code) {
    errors.code = 'field is required';
  }
  if (!team.shortName) {
    errors.shortName = 'field is required';
  }
  return errors;
}

interface ILogoValue {
  small?: string;
  medium?:
    | {
        rawFile: File;
      }
    | string;
}

function convert(file: File) {
  return new Promise<Buffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      const img = await Jimp.read(reader.result as any);
      resolve(
        img.quality(70).resize(32, Jimp.AUTO).getBufferAsync(Jimp.MIME_PNG)
      );
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

const TeamForm = (props: SimpleFormProps) => {
  const handleSave = async (
    values: { id: string; logo?: ILogoValue },
    redirect: string,
    ...params: any[]
  ) => {
    const logo = await (async () => {
      if (!values.logo?.medium) {
        return {
          small: null,
          medium: null,
        };
      }

      if (typeof values.logo.medium === 'string') {
        return {
          small: values.logo.small!,
          medium: values.logo.medium,
        };
      }

      return {
        small: await uploadToStorage(
          {
            type: Storage.StorageType.PUBLIC_TEAM_LOGO,
            teamId: values.id,
            format: Storage.AssetFormat.SMALL,
          },
          await convert(values.logo.medium.rawFile)
        ),
        medium: await uploadToStorage(
          {
            type: Storage.StorageType.PUBLIC_TEAM_LOGO,
            teamId: values.id,
            format: Storage.AssetFormat.MEDIUM,
          },
          values.logo.medium.rawFile
        ),
      };
    })();

    return props.save(
      {
        ...values,
        logo,
      },
      redirect,
      ...params
    );
  };

  return (
    <SimpleForm {...props} save={handleSave} validate={validate}>
      <TextInput source="name" disabled />
      <TextInput source="type" disabled />
      <TextInput source="shortName" />
      <TextInput source="code" />
      <BooleanInput source="licensed" />
      <ColorInput source="shirtColor" />
      <StorageImageInput source="logo.medium" label="Logo (300x300)" />
    </SimpleForm>
  );
};

export const TeamEdit = (props: EditProps) => (
  <Edit {...props}>
    {
      // @ts-ignore
      <TeamForm />
    }
  </Edit>
);
