import { Typography } from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  FormTab,
  NumberInput,
  ReferenceInput,
  TabbedForm,
  TabbedFormProps,
  TextInput,
  TranslatableInputs,
} from 'react-admin';
import styled from 'styled-components';
import { enumToArray, LANG, Storage } from 'ultimate-league-common';
import { IAsset } from '~business/common/assetTypes';
import { StorageImageInput } from '~business/storage/StorageImageInput';
import { uploadToStorage } from '~business/storage/service';
import { LootTableField } from '~technical/filters/lootTable';

import { uploadPreviewToStorage, validateForm } from './editService';
import { useMonetization } from './hooks';
import { IPackConfig } from './types';

const Requirements = styled.div`
  padding: 0.6em 1em 0;
  border: 1px solid #d9d9d9;

  h6 {
    margin: 0.4em 0 0.5em;
  }
`;

export const PackConfigForm = (props: TabbedFormProps) => {
  const { record: packConfig } = props;
  const { isMonetizationEnabled, hasExistingPacks } = useMonetization(
    packConfig as IPackConfig
  );

  const handleSave: TabbedFormProps['save'] = async (
    values,
    redirect,
    ...params
  ) => {
    const { id } = values as { id: string };

    const visuals = id
      ? await Promise.all(
          values.visuals.map(async (visual: IAsset, index: number) => {
            if (typeof visual === 'string') {
              return visual;
            }

            return uploadToStorage(
              {
                type: Storage.StorageType.PUBLIC_PACK_VISUAL,
                packConfigId: String(id),
                index,
              },
              visual.rawFile
            );
          })
        )
      : [];

    let monetization;
    if (
      values.isMonetizationEnabled &&
      values.monetization &&
      Object.keys(values.monetization).length
    ) {
      const { majorPreview, minorPreview, ...restMonetization } =
        values.monetization;
      monetization = {
        ...restMonetization,
        majorPreview: await uploadPreviewToStorage(
          id,
          majorPreview,
          Storage.StorageType.PUBLIC_PACK_MAJOR_PREVIEW
        ),
        minorPreview: await uploadPreviewToStorage(
          id,
          minorPreview,
          Storage.StorageType.PUBLIC_PACK_MINOR_PREVIEW
        ),
      };
    }

    return props.save?.(
      {
        ...values,
        visuals,
        monetization,
      },
      packConfig?.id
        ? redirect
        : (_, packConfigId) => `/packConfig/${packConfigId}`,
      ...params
    );
  };

  return (
    <TabbedForm {...props} validate={validateForm} save={handleSave}>
      <FormTab label="Pack summary">
        <TextInput source="name" />
        <ReferenceInput
          source="lootTable"
          reference="lootTable"
          filterToQuery={(search: string) => ({
            [LootTableField.GOOGLE_SHEET_TITLE]: search,
          })}
        >
          <AutocompleteInput
            optionText={LootTableField.GOOGLE_SHEET_TITLE}
            optionValue="id"
          />
        </ReferenceInput>
        <BooleanInput label="Monetization" source="isMonetizationEnabled" />
      </FormTab>
      {packConfig?.id && (
        <FormTab label="Content">
          <StorageImageInput source="visuals" multiple />
          <TranslatableInputs locales={enumToArray(LANG)}>
            <RichTextInput source="content" />
            <RichTextInput source="details" />
          </TranslatableInputs>
        </FormTab>
      )}
      {isMonetizationEnabled && (
        <FormTab label="Monetization">
          <NumberInput source="monetization.price" label="Price" />
          <NumberInput
            source="monetization.supply"
            label={`Supply ${hasExistingPacks ? '(not editable)' : ''}`}
            disabled={hasExistingPacks}
          />
          <BooleanInput
            source="monetization.allowUlcPurchase"
            label="Allow ULC purchase"
          />
          <NumberInput
            source="monetization.maximumPurchasePerPlayer"
            label="Maximum purchase per player"
          />
          <DateTimeInput
            source="monetization.announcingDate"
            label="Announcing date"
          />
          <StorageImageInput
            source="monetization.majorPreview.medium"
            label="Major preview"
          />
          <StorageImageInput
            source="monetization.minorPreview.medium"
            label="Minor preview"
          />
          <DateTimeInput source="monetization.startDate" label="Start date" />
          <DateTimeInput source="monetization.endDate" label="End date" />
          <Requirements>
            <Typography variant="h6">Requirements</Typography>
            <NumberInput
              source="monetization.requirements.collectionScore"
              label="Collection score"
            />
            <NumberInput
              source="monetization.requirements.unaAmount"
              label="UNA amount"
            />
          </Requirements>
        </FormTab>
      )}
    </TabbedForm>
  );
};
