import * as React from 'react';
import { useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import { buildURL, extractCID } from '~technical/ipfs';
import { error } from '~technical/logger';

import { INFTMetadata, IRecordNFT } from '../interfaces';
import { useCardGridListStyles } from './NFTCardGridList.styles';
import { GeneratedCardItem, DraftCardItem } from './item/NFTCardGridListItem';

const LoadingGridList = () => {
  const classes = useCardGridListStyles();
  return (
    <div className={classes.grid}>
      {Array.from(Array(20)).map((_, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={key} />
      ))}
    </div>
  );
};

function getMetadata(
  metadatas: Record<string, Promise<INFTMetadata> | INFTMetadata>,
  metadataCID: string
) {
  const metadata = metadatas[metadataCID];
  if (metadata instanceof Promise) {
    return undefined;
  }

  return metadata;
}

function getImageURL(
  metadatas: Record<string, Promise<INFTMetadata> | INFTMetadata>,
  metadataCID: string
) {
  try {
    const metadata = getMetadata(metadatas, metadataCID);
    return metadata && buildURL(extractCID(metadata.image));
  } catch (e) {
    error(e);
  }

  return undefined;
}

const LoadedGridList = () => {
  const { ids, data } = useListContext<IRecordNFT>();
  const classes = useCardGridListStyles();

  const [metadatas, setMetadatas] = useState<
    Record<string, Promise<INFTMetadata> | INFTMetadata>
  >({});

  useEffect(() => {
    if (!ids || !data) {
      return;
    }

    setMetadatas((oldMetadatas) =>
      (ids as string[]).reduce((acc, id) => {
        const metadataCID = data[id]?.metadataCID;

        if (!metadataCID) {
          return acc;
        }

        if (!acc[metadataCID]) {
          acc[metadataCID] = fetch(buildURL(metadataCID))
            .then((response) => response.json())
            .then((metadata) => {
              setMetadatas((o) => ({
                ...o,
                [metadataCID]: metadata,
              }));

              return metadata;
            });
        }

        return acc;
      }, oldMetadatas)
    );
  }, [data, ids, setMetadatas]);

  if (!ids || !data) return null;

  return (
    <div className={classes.grid}>
      {ids.map((id) => {
        const card = data[id];

        return card.metadataCID === undefined ? (
          <DraftCardItem key={card.id} card={card} />
        ) : (
          <GeneratedCardItem
            key={card.id}
            card={card}
            nftImageURL={getImageURL(metadatas, card.metadataCID)}
          />
        );
      })}
    </div>
  );
};

export const NFTCardGridList = () => {
  const { loaded } = useListContext();
  return loaded ? <LoadedGridList /> : <LoadingGridList />;
};
