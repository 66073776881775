import { Card, CardContent, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import Firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import * as React from 'react';
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { defaultTheme, Notification, useLogin, useNotify } from 'react-admin';
import { Authorization, User } from 'ultimate-league-common';
import { fetchApi, submitData } from '~technical/api';
import { firebase } from '~technical/firebase';
import { error } from '~technical/logger';

function firebaseSign(
  token: string
): Promise<Authorization.IAuthorization | Authorization.IPartialAuthorization> {
  return submitData('/auth/firebase-sign', {
    token,
  });
}

function faFinishSign(
  faToken: string,
  totp: string
): Promise<Authorization.IAuthorization> {
  return submitData('/auth/fa-finish-sign', {
    faToken,
    totp,
  });
}

function isPartial(
  auth: Authorization.IAuthorization | Authorization.IPartialAuthorization
): auth is Authorization.IPartialAuthorization {
  return !!(auth as Authorization.IPartialAuthorization).faToken;
}

export const FirebaseLoginPage = () => {
  const login = useLogin();
  const notify = useNotify();
  const [ui] = useState(() => new firebaseui.auth.AuthUI(firebase.auth()));
  const [faToken, setFaToken] = useState('');
  const [checkFA, setCheckFA] = useState('');
  const [faLoading, setFaLoading] = useState(false);
  const handleCheckFAChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setCheckFA(e.target.value);
    },
    [setCheckFA]
  );
  const container = useRef<HTMLDivElement>(null);

  const handleCredentials = useCallback(
    async (credentials: Authorization.IAuthorization) => {
      const response = await fetchApi('/user/me', undefined, credentials);
      const me: User.IUser = await response.json();
      if (me.role !== User.Role.MODERATOR) {
        notify(
          'Only moderator or roles above can access the backoffice.',
          'error'
        );
        const { current: currentContainer } = container;
        if (!currentContainer) {
          throw new Error(
            'Invalid state, firebase sign-in-up container is not mounted.'
          );
        }
        setFaToken('');
        setCheckFA('');
        ui.start(currentContainer, {});
        return;
      }

      login({ credentials });
    },
    [login, notify, ui]
  );

  const handleFirebaseUser = useCallback(
    async (user: Firebase.User) => {
      const token = await user.getIdToken(true);
      const authResult = await firebaseSign(token);
      if (isPartial(authResult)) {
        setFaToken(authResult.faToken);
        return undefined;
      }

      await handleCredentials(authResult);
      return undefined;
    },
    [handleCredentials, setFaToken]
  );

  const handleCheck2FA = useCallback(async () => {
    setFaLoading(true);
    try {
      const credentials = await faFinishSign(faToken, checkFA);
      await handleCredentials(credentials);
    } catch (e) {
      notify('Could not sign-in. Double check your code and retry.', 'warning');
      setFaLoading(false);
      throw e;
    }
  }, [handleCredentials, checkFA, faToken, setFaLoading, notify]);

  useEffect(() => {
    const { current: currentContainer } = container;
    if (!currentContainer) {
      throw new Error(
        'Invalid state, firebase sign-in-up container is not mounted.'
      );
    }
    ui.start(currentContainer, {
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          handleFirebaseUser(authResult.user).catch(error);
          return false;
        },
      },
      signInOptions: [
        {
          provider: Firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
        },
        {
          provider: Firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          clientId: process.env.GOOGLE_CLIENT_ID,
        },
        Firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    });

    return () => {
      ui.delete().catch(error);
    };
  }, [handleFirebaseUser, ui]);

  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <div
        style={{
          height: '100vh',
          background: 'grey',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card>
          <CardContent style={{ width: 350, maxWidth: '90vw' }}>
            <Typography color="textSecondary">
              Ultimate-League Backoffice
            </Typography>
            <div ref={container} />
            {faToken && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 24,
                }}
              >
                <FormControl>
                  <InputLabel htmlFor="totp">2-factor auth code</InputLabel>
                  <Input
                    style={{ marginRight: 8 }}
                    id="totp"
                    value={checkFA}
                    onChange={handleCheckFAChange}
                    disabled={faLoading}
                  />
                </FormControl>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleCheck2FA}
                  disabled={faLoading}
                >
                  {faLoading ? 'Loading...' : 'Enter'}
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
      <Notification />
    </ThemeProvider>
  );
};
