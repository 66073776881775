import React from 'react';
import { BooleanField } from 'react-admin';

interface IProps {
  source: string;
  record?: any;
}

export const PresenceField = ({ source, record, ...props }: IProps) => (
  <BooleanField
    source={source}
    record={{ ...record, [source]: !!(record || {})[source] }}
    {...props}
  />
);
