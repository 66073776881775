import { IconButton, Typography } from '@material-ui/core';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import LinkIcon from '@material-ui/icons/Link';
import * as React from 'react';
import styled from 'styled-components';
import { Blockchain } from 'ultimate-league-common';

import { IGeneratedCard, IDraftCard } from '../../interfaces';
import { GenerateCardConfirmButton } from './GenerateCardConfirmButton';

// NB: why multiple destructuring:
// https://flutterq.com/solved-uncaught-referenceerror-process-is-not-defined/
const { BSC_SCAN_URL } = process.env;
const { IPFS_GATEWAY } = process.env;
const { ULTIMATE_LEAGUE_NFT_ADDRESS } = process.env;

if (!BSC_SCAN_URL) {
  throw new Error('Missing env BSC_SCAN_URL');
}
if (!IPFS_GATEWAY) {
  throw new Error('Missing env IPFS_GATEWAY');
}
if (!ULTIMATE_LEAGUE_NFT_ADDRESS) {
  throw new Error('Missing env ULTIMATE_LEAGUE_NFT_ADDRESS');
}

interface IGeneratedCardItemProps {
  card: IGeneratedCard;
  nftImageURL?: string;
}

export const GeneratedCardItem = ({
  card,
  nftImageURL,
}: IGeneratedCardItemProps) => (
  <div
    style={{
      background: `center / contain no-repeat url(${nftImageURL})`,
    }}
  >
    <div className="actions">
      <IconButton
        title="See metadata on IPFS"
        color="primary"
        href={`${IPFS_GATEWAY}/ipfs/${card.metadataCID}`}
        target="_blank"
      >
        <FeaturedPlayListIcon />
      </IconButton>

      {!nftImageURL ? null : (
        <IconButton
          title="See image on IPFS"
          color="primary"
          href={nftImageURL}
          target="_blank"
        >
          <InsertPhotoIcon />
        </IconButton>
      )}

      {!card.mint ? null : (
        <IconButton
          title="See NFT on the blockchain"
          color="primary"
          href={`${BSC_SCAN_URL}/token/${ULTIMATE_LEAGUE_NFT_ADDRESS}?a=${card.mint.nft}`}
          target="_blank"
        >
          <LinkIcon />
        </IconButton>
      )}
    </div>
  </div>
);

interface IDraftCardItemProps {
  card: IDraftCard;
}

const StyledNftItem = styled.div`
  background-color: #d1d1d1;
  justify-content: center;
`;

const getAttribute = (nft: IDraftCard, type: string) =>
  nft.recipe.metadata.attributes.find((a) => a.type === type)?.value;

export const DraftCardItem = ({ card }: IDraftCardItemProps) => (
  <StyledNftItem>
    <Typography variant="body1">
      <div style={{ fontWeight: 'bold' }}>{card.recipe.metadata.title}</div>
      <div>{card.recipe.metadata.description}</div>
      <div>
        Rarity:
        {
          Blockchain.NFTCard.RarityLevel[
            getAttribute(card, 'rarity') as Blockchain.NFTCard.RarityLevel
          ]
        }
      </div>
      <div>
        Edition: {getAttribute(card, 'edition')}/{getAttribute(card, 'supply')}
      </div>
      <div className={`actions ${card.mintStatus ? 'minting' : ''}`}>
        <GenerateCardConfirmButton card={card} />
      </div>
    </Typography>
  </StyledNftItem>
);
