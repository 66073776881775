import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { ConfigProvider } from '~business/ConfigContext';
import { AthleteEdit } from '~business/athlete/AthleteEdit';
import { AthleteList } from '~business/athlete/AthleteList';
import { AthleteShow } from '~business/athlete/AthleteShow';
import { BackupCreate } from '~business/backup/BackupCreate';
import { BackupList } from '~business/backup/BackupList';
import { BackupShow } from '~business/backup/BackupShow';
import { CompetitionEdit } from '~business/competition/CompetitionEdit';
import { CompetitionList } from '~business/competition/CompetitionList';
import { CompetitionShow } from '~business/competition/CompetitionShow';
import { CronList } from '~business/cron/CronList';
import { CronShow } from '~business/cron/CronShow';
import { FilterEdit } from '~business/filter/FilterEdit';
import { FilterList } from '~business/filter/FilterList';
import { GameWeekCreate } from '~business/gameweek/GameWeekCreate';
import { GameWeekEdit } from '~business/gameweek/GameWeekEdit';
import { GameWeekList } from '~business/gameweek/GameWeekList';
import { Layout } from '~business/layout';
import { MatchList } from '~business/match/MatchList';
import { NftBatchCreate } from '~business/nft-batch/NftBatchCreate';
import { NftBatchEdit } from '~business/nft-batch/NftBatchEdit';
import { NftBatchList } from '~business/nft-batch/NftBatchList';
import { NftBatchShow } from '~business/nft-batch/NftBatchShow';
import { NftCardStatsList } from '~business/nft-card-stats/NftCardStatsList';
import { NFTCardList } from '~business/nft-card/NFTCardList';
import { PackConfigCreate } from '~business/pack/pack-config/PackConfigCreate';
import { PackConfigEdit } from '~business/pack/pack-config/PackConfigEdit';
import { PackConfigList } from '~business/pack/pack-config/PackConfigList';
import { SeasonCreate } from '~business/season/SeasonCreate';
import { SeasonEdit } from '~business/season/SeasonEdit';
import { SeasonList } from '~business/season/SeasonList';
import { SeasonShow } from '~business/season/SeasonShow';
import { TeamEdit } from '~business/team/TeamEdit';
import { TeamList } from '~business/team/TeamList';
import { UserEdit } from '~business/user/UserEdit';
import { UserList } from '~business/user/UserList';
import { authProvider } from '~technical/authProvider';
import { dataProvider } from '~technical/dataProvider';

import { FirebaseLoginPage } from './FirebaseLoginPage';

export const App = () => (
  <ConfigProvider>
    <Admin
      loginPage={FirebaseLoginPage}
      /* TODO: Fix types */
      /* @ts-ignore */
      dataProvider={dataProvider}
      /* TODO: Fix types */
      /* @ts-ignore */
      authProvider={authProvider}
      layout={Layout}
      customRoutes={[
        <Route
          exact
          path="/tools/cards"
          component={() => <p>Hello World</p>}
        />,
      ]}
    >
      <Resource
        name="competition"
        list={CompetitionList}
        edit={CompetitionEdit}
        show={CompetitionShow}
      />
      <Resource name="country" />
      <Resource
        name="gameweek"
        create={GameWeekCreate}
        list={GameWeekList}
        edit={GameWeekEdit}
      />
      <Resource name="match" list={MatchList} />
      <Resource
        name="backup"
        list={BackupList}
        show={BackupShow}
        create={BackupCreate}
      />
      <Resource
        name="nftcardseason"
        list={SeasonList}
        create={SeasonCreate}
        edit={SeasonEdit}
        show={SeasonShow}
      />
      <Resource
        name="nftbatch"
        list={NftBatchList}
        create={NftBatchCreate}
        edit={NftBatchEdit}
        show={NftBatchShow}
      />
      <Resource name="team" list={TeamList} edit={TeamEdit} />
      <Resource name="user" list={UserList} edit={UserEdit} />
      <Resource name="storage" />
      <Resource
        name="athlete"
        list={AthleteList}
        edit={AthleteEdit}
        show={AthleteShow}
      />
      <Resource name="NftCard" list={NFTCardList} />
      <Resource name="NftCardStatsView" list={NftCardStatsList} />
      <Resource name="Filter" list={FilterList} edit={FilterEdit} />
      <Resource
        name="packConfig"
        list={PackConfigList}
        create={PackConfigCreate}
        edit={PackConfigEdit}
      />
      <Resource name="lootTable" />
      <Resource name="wallet" />
      <Resource name="cron" list={CronList} show={CronShow} />
    </Admin>
  </ConfigProvider>
);
