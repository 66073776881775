"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCard = exports.CardBrand = exports.Type = void 0;
var Type;
(function (Type) {
    Type["CARD"] = "card";
    Type["OTHER"] = "other";
})(Type = exports.Type || (exports.Type = {}));
var CardBrand;
(function (CardBrand) {
    CardBrand["MASTERCARD"] = "mastercard";
    CardBrand["AMEX"] = "amex";
    CardBrand["DISCOVER"] = "discover";
    CardBrand["DINERSCLUB"] = "diners";
    CardBrand["JCB"] = "jcb";
    CardBrand["VISA"] = "visa";
})(CardBrand = exports.CardBrand || (exports.CardBrand = {}));
function isCard(payment) {
    return payment.type === Type.CARD;
}
exports.isCard = isCard;
