import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { IBatchIngredientError, SoccerData } from 'ultimate-league-common';

import { ISavedNftBatch } from '../../types';
import { BatchErrors } from './BatchErrors';
import { ItemId } from './styles';

export const UNKNOWN_ATHLETE = 'unknown';

interface IPublicationErrors {
  erroredBatch?: ISavedNftBatch;
  errors: IBatchIngredientError[];
}

type IErrorsByAthlete = Record<
  SoccerData.Athlete.IAthlete['id'],
  IBatchIngredientError[]
>;

export const PublicationErrors = ({
  erroredBatch,
  errors,
}: IPublicationErrors) => {
  const errorsByAthlete = useMemo<IErrorsByAthlete>(
    () =>
      errors.reduce((acc, error) => {
        const key = error.athleteId || UNKNOWN_ATHLETE;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(error);
        return acc;
      }, {}),
    [errors]
  );

  if (!erroredBatch || errors.length === 0) {
    return null;
  }

  return (
    <Box color="#bc0000" margin="1em" padding="1em" border="1px solid red">
      <Typography variant="body1">
        {'⚠ Publication of batch '}
        <b>{`${erroredBatch.label} `}</b>
        <ItemId>{`(#${erroredBatch.id})`}</ItemId>
        {' encountered errors:'}
        <br />
        <br />
      </Typography>

      {Object.keys(errorsByAthlete).map((athId) => (
        <BatchErrors key={athId} errors={errorsByAthlete[athId]} />
      ))}
    </Box>
  );
};
