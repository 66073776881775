import { Authorization as AuthorizationCommon } from 'ultimate-league-common';

import { Authorization } from './authorization';

const auth = Authorization.getInstance();

interface ILoginParams {
  credentials: AuthorizationCommon.IAuthorization;
}

async function login({ credentials }: ILoginParams) {
  auth.setCredentials(credentials);
}

async function logout() {
  auth.setCredentials(undefined);
}

function checkAuth() {
  return auth.getCredentials() ? Promise.resolve() : Promise.reject();
}

function getPermissions() {
  return Promise.resolve();
}

export const authProvider = {
  login,
  logout,
  checkAuth,
  getPermissions,
};
