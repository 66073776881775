"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletStatus = exports.isWithdrawWalletTransaction = exports.isMarketplaceWalletTransaction = exports.MarketplaceTransactionErrorCode = exports.TransactionStatus = exports.TransactionType = void 0;
var TransactionType;
(function (TransactionType) {
    TransactionType["WITHDRAW"] = "WITHDRAW";
    TransactionType["CREATE_SALE"] = "CREATE_SALE";
    TransactionType["ACCEPT_SALE"] = "ACCEPT_SALE";
    TransactionType["DESTROY_SALE"] = "DESTROY_SALE";
})(TransactionType = exports.TransactionType || (exports.TransactionType = {}));
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["CREATED"] = "CREATED";
    TransactionStatus["SENT"] = "SENT";
    TransactionStatus["SUCCESS"] = "SUCCESS";
    TransactionStatus["FAILED"] = "FAILED";
})(TransactionStatus = exports.TransactionStatus || (exports.TransactionStatus = {}));
var MarketplaceTransactionErrorCode;
(function (MarketplaceTransactionErrorCode) {
    MarketplaceTransactionErrorCode["ALREADY_EXISTS"] = "ALREADY_EXISTS";
    MarketplaceTransactionErrorCode["NOT_AVAILABLE"] = "NOT_AVAILABLE";
})(MarketplaceTransactionErrorCode = exports.MarketplaceTransactionErrorCode || (exports.MarketplaceTransactionErrorCode = {}));
function isMarketplaceWalletTransaction(walletTransaction) {
    return [
        TransactionType.DESTROY_SALE,
        TransactionType.CREATE_SALE,
        TransactionType.ACCEPT_SALE,
    ].includes(walletTransaction.transactionType);
}
exports.isMarketplaceWalletTransaction = isMarketplaceWalletTransaction;
function isWithdrawWalletTransaction(walletTransaction) {
    return walletTransaction.transactionType === TransactionType.WITHDRAW;
}
exports.isWithdrawWalletTransaction = isWithdrawWalletTransaction;
var WalletStatus;
(function (WalletStatus) {
    WalletStatus["PENDING"] = "PENDING";
    WalletStatus["RESOLVED"] = "RESOLVED";
})(WalletStatus = exports.WalletStatus || (exports.WalletStatus = {}));
