import { alpha, makeStyles } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  ArrayInput,
  Button,
  NumberInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import styled from 'styled-components';
import { enumToArray, FontStyles } from 'ultimate-league-common';
import { StorageFileInput } from '~business/storage/StorageFileInput';

import { IFont } from '../types';

const defaultValues = {
  fontStyle: FontStyles.Normal,
  fontWeight: 400,
};

const fontStyleChoice = enumToArray(FontStyles).map((fontStyle) => ({
  id: fontStyle,
  name: fontStyle,
}));

const useStyles = makeStyles((theme) => ({
  removeButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
    },
    margin: '8px 24px',
  },
}));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.7em;
  padding: 0.4em 0.7em;
  border: 1px solid lightgrey;
`;
const InlineFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  & > * {
    flex-grow: 1;
  }
  & > *:not(:last-child) {
    margin-right: 1em;
  }
`;

const CardFontIterator = ({
  fields,
}: PropsWithChildren<FieldArrayRenderProps<IFont, HTMLDivElement>>) => {
  const classes = useStyles();
  const [confirmRemove, setConfirmRemove] = useState(
    new Array(fields.value?.length || 0).fill(false)
  );

  useEffect(() => {
    if (confirmRemove.length < (fields.value?.length || 0)) {
      confirmRemove.push(false);
    }
  }, [confirmRemove, fields]);

  const handleAdd = () => fields.push({ ...defaultValues });

  const handleRemove = (index: number) => () => {
    if (confirmRemove[index]) {
      fields.remove(index);
      setConfirmRemove([
        ...confirmRemove.slice(0, index),
        ...confirmRemove.slice(index + 1),
      ]);
    } else {
      setConfirmRemove([
        ...confirmRemove.slice(0, index),
        true,
        ...confirmRemove.slice(index + 1),
      ]);
    }
  };

  return (
    <>
      <Button label="Add font" size="large" onClick={handleAdd} />

      {fields.map((key: any, index: number) => (
        <Container key={key}>
          <InlineFields>
            <TextInput label="Font family" source={`${key}.fontFamily`} />
            <SelectInput
              label="Font style"
              source={`${key}.fontStyle`}
              choices={fontStyleChoice}
            />
            <NumberInput label="Font weight" source={`${key}.fontWeight`} />
          </InlineFields>
          <StorageFileInput
            source={`${key}.font`}
            label="Font file (ttf, woff, woff2, otf)"
            // NB: parameter `accept` does not work on windows font fonts (rawFile.type is empty)
          />
          <Button
            label={
              confirmRemove[index] ? 'Click again to confirm' : 'Remove font'
            }
            startIcon={
              confirmRemove[index] ? undefined : <RemoveCircleOutlineIcon />
            }
            className={classes.removeButton}
            onClick={handleRemove(index)}
          />
        </Container>
      ))}
    </>
  );
};

export const FontsInput = () => (
  <ArrayInput source="cardFonts" label="">
    {
      // @ts-ignore
      <CardFontIterator />
    }
  </ArrayInput>
);
