import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { linkToRecord } from 'react-admin';
import { Link } from 'react-router-dom';
import { IBatchIngredientError } from 'ultimate-league-common';
import { buildQuery, fetchApi } from '~technical/api';

import { BatchErrorDetail } from './BatchErrorDetail';
import { UNKNOWN_ATHLETE } from './index';
import { ItemId, Ul } from './styles';

interface IBatchErrorsProps {
  errors: IBatchIngredientError[];
}

export const BatchErrors = ({ errors }: IBatchErrorsProps) => {
  const { athleteId } = errors[0];

  const [athlete, setAthlete] = useState<string>();
  const athleteLink =
    !athleteId || athleteId === UNKNOWN_ATHLETE
      ? ''
      : linkToRecord('/athlete', athleteId, 'edit');

  useEffect(() => {
    if (!athleteId || athleteId === UNKNOWN_ATHLETE) {
      setAthlete(undefined);
      return;
    }
    (async () => {
      const response = await fetchApi(
        `/backoffice/athlete/${athleteId}?${buildQuery({
          select: 'matchName',
        })}`
      );
      const { matchName } = await response.json();
      setAthlete(matchName || athleteId);
    })();
  }, [athleteId]);

  return (
    <Typography variant="body1">
      {athlete && (
        <>
          <span>Athlete </span>
          <Link to={athleteLink} target="_blank">
            {athlete}
          </Link>
          <ItemId>{` (#${athleteId})`}</ItemId>
        </>
      )}
      <Ul>
        {errors.map(({ teamId, reason }) => (
          <BatchErrorDetail
            key={`${teamId}-${reason}`}
            teamId={teamId}
            reason={reason}
          />
        ))}
      </Ul>
    </Typography>
  );
};
