"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContextType = exports.EventType = void 0;
var EventType;
(function (EventType) {
    EventType["FTUE"] = "FTUE";
})(EventType = exports.EventType || (exports.EventType = {}));
var ContextType;
(function (ContextType) {
    ContextType["GAMEMODE"] = "GAMEMODE";
    ContextType["EVENT"] = " EVENT";
})(ContextType = exports.ContextType || (exports.ContextType = {}));
