"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QUEUE = void 0;
var QUEUE;
(function (QUEUE) {
    QUEUE["API_PREPARE_ATHLETE_RARITY"] = "API_PREPARE_ATHLETE_RARITY";
    QUEUE["API_NFT_TRANSFERRED"] = "API_NFT_TRANSFERRED";
    QUEUE["API_SALE_CREATED"] = "API_SALE_CREATED";
    QUEUE["API_SALE_EDITED"] = "API_SALE_EDITED";
    QUEUE["API_SALE_ACCEPTED"] = "API_SALE_ACCEPTED";
    QUEUE["API_SALE_DESTROYED"] = "API_SALE_DESTROYED";
    QUEUE["API_MARKET_BUY_PAYMENT_SUCCESS"] = "API_MARKET_BUY_PAYMENT_SUCCESS";
    QUEUE["API_PAYMENT_SALE_SUCCESS"] = "API_PAYMENT_SALE_SUCCESS";
    QUEUE["API_PAYMENT_SALE_FAILURE"] = "API_PAYMENT_SALE_FAILURE";
    QUEUE["API_TRANSACTION_STATUS_CHANGE"] = "API_TRANSACTION_STATUS_CHANGE";
})(QUEUE = exports.QUEUE || (exports.QUEUE = {}));
