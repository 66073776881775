"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeMatchesHighlight = void 0;
var score_1 = require("../../core-game/score");
function sortByAnyMatchDate(a, b) {
    return new Date(a.matchDate).getTime() - new Date(b.matchDate).getTime();
}
function computeMatchesHighlight(scores, lastX, includeNotPlayed) {
    var lastScores = __spreadArray([], scores).sort(sortByAnyMatchDate).slice(-lastX);
    if (lastScores.length === 0) {
        return undefined;
    }
    var lastPlayedMatches = includeNotPlayed
        ? lastScores
        : lastScores.filter(function (score) { var _a; return ((_a = score.categoryScore[score_1.Category.HIDDEN].ul_starting) === null || _a === void 0 ? void 0 : _a.eventCount) === 1; });
    var initialHighlights = {
        averageScore: 0,
        minutesPlayed: {
            total: 0,
            max: 0,
        },
        matchesPlayed: {
            total: lastPlayedMatches.length,
            max: Math.min(lastX, lastScores.length),
        },
    };
    return lastPlayedMatches.reduce(function (highlightsAcc, score) {
        var _a, _b;
        var athleteMinutesPlayed = (_b = (_a = score.categoryScore[score_1.Category.HIDDEN].minsPlayed) === null || _a === void 0 ? void 0 : _a.eventCount) !== null && _b !== void 0 ? _b : 0;
        return __assign(__assign({}, highlightsAcc), { averageScore: highlightsAcc.averageScore +
                score.totalScore / lastPlayedMatches.length, minutesPlayed: {
                total: highlightsAcc.minutesPlayed.total + athleteMinutesPlayed,
                max: highlightsAcc.minutesPlayed.max + score.matchDurationMinutes,
            } });
    }, initialHighlights);
}
exports.computeMatchesHighlight = computeMatchesHighlight;
