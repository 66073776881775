"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackDirectories = exports.AssetFormat = exports.StorageType = void 0;
var StorageType;
(function (StorageType) {
    StorageType["TOOL"] = "TOOL";
    StorageType["LEAGUE_BANNER"] = "LEAGUE_BANNER";
    StorageType["LEAGUE_ICON"] = "LEAGUE_ICON";
    StorageType["PUBLIC_TEAM_LOGO"] = "PUBLIC_TEAM_LOGO";
    StorageType["PUBLIC_ATHLETE_MAIN"] = "PUBLIC_ATHLETE_MAIN";
    StorageType["ATHLETE_CARD_ASSET"] = "ATHLETE_CARD_ASSET";
    StorageType["PUBLIC_PACK_VISUAL"] = "PUBLIC_PACK_VISUAL";
    StorageType["PUBLIC_PACK_MAJOR_PREVIEW"] = "PUBLIC_PACK_MAJOR_PREVIEW";
    StorageType["PUBLIC_PACK_MINOR_PREVIEW"] = "PUBLIC_PACK_MINOR_PREVIEW";
    StorageType["PUBLIC_COMPETITION_ASSET"] = "PUBLIC_COMPETITION_ASSET";
    StorageType["PUBLIC_USER_PROFILE_PICTURE"] = "PUBLIC_USER_PROFILE_PICTURE";
})(StorageType = exports.StorageType || (exports.StorageType = {}));
var AssetFormat;
(function (AssetFormat) {
    AssetFormat["MEDIUM"] = "m";
    AssetFormat["SMALL"] = "s";
})(AssetFormat = exports.AssetFormat || (exports.AssetFormat = {}));
var PackDirectories;
(function (PackDirectories) {
    PackDirectories["VISUALS"] = "visuals";
    PackDirectories["MAJOR_PREVIEW"] = "major-preview";
    PackDirectories["MINOR_PREVIEW"] = "minor-preview";
})(PackDirectories = exports.PackDirectories || (exports.PackDirectories = {}));
