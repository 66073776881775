import { Box, Button, Slider } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, {
  cloneElement,
  KeyboardEventHandler,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import {
  AutocompleteInput,
  Filter,
  FilterPayload,
  List,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  useCreate,
  useListContext,
  useNotify,
} from 'react-admin';
import { positionChoices } from '~business/athlete/common';
import { rarityLevelChoice } from '~business/common/assetTypes';
import { reactAdminCardFilterToQuery } from '~business/filter/service';
import { AthleteField } from '~technical/filters/athlete';
import { CardField } from '~technical/filters/nftCard';
import { TeamField } from '~technical/filters/team';

import { SaveFiltersDialog } from './SaveFiltersDialog';
import { NFTCardGridList } from './grid-list/NFTCardGridList';
import { KeyValueObject } from './interfaces';

function toText(age: number) {
  return ` ${age}yo `;
}

const AgeSliderField = ({ source }: any) => {
  const { filterValues, displayedFilters, setFilters } = useListContext();
  const [value, setValue] = useState(filterValues[source] || [0, 100]);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);

      setFilters(
        {
          ...filterValues,
          [source]: newValue,
        },
        displayedFilters
      );
    },
    [setFilters, filterValues, displayedFilters, source]
  );

  return (
    <Box width={300}>
      <Slider
        onChange={handleChange}
        value={value}
        valueLabelDisplay="auto"
        valueLabelFormat={toText}
      />
    </Box>
  );
};

interface IListActionsProps {
  setSaveFiltersDialogVisibility: (visibilityStatus: boolean) => void;
  setFiltersConfig: (filtersConfigValueAction: SetStateAction<any>) => void;
  filters?: ReactElement;
}

interface ICardFilterProps {}

interface ICardListProps {
  filter?: FilterPayload;
  resource?: string;
  basePath?: string;
}

interface IAthlete {
  firstName: string;
  lastName: string;
  matchName: string;
}

const ListActions = (props: IListActionsProps) => {
  const listContext = useListContext();

  const { filters } = props;
  const isAddFiltersButtonVisible =
    !isEmpty(listContext.filterValues) &&
    typeof listContext.filterValues.level === 'number';

  return (
    <TopToolbar>
      {isAddFiltersButtonVisible ? (
        <Button
          onClick={() => {
            props.setSaveFiltersDialogVisibility(true);
            props.setFiltersConfig(listContext.filterValues);
          }}
        >
          Save filters
        </Button>
      ) : null}
      {filters ? cloneElement(filters, { context: 'button' }) : null}
    </TopToolbar>
  );
};

const CardFilter = (props: ICardFilterProps) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    // TODO: fix types
    // @ts-ignore
    <Filter {...props} onKeyPress={handleKeyPress}>
      <SelectInput
        label="Rarity level"
        source={CardField.SEARCH_LEVEL}
        choices={rarityLevelChoice}
      />

      <ReferenceInput
        label="Athlete"
        source={CardField.SEARCH_ATHLETE}
        reference="athlete"
        filterToQuery={(search: string) => ({ [AthleteField.JOKER]: search })}
      >
        <AutocompleteInput
          optionText="matchName"
          optionValue="id"
          matchSuggestion={(filter: string, choice: Partial<IAthlete>) =>
            !filter ||
            choice.firstName?.includes(filter) ||
            choice.lastName?.includes(filter) ||
            choice.matchName?.includes(filter)
          }
        />
      </ReferenceInput>

      <SelectInput
        label="Athlete position"
        source={CardField.SEARCH_ATHLETE_POSITION}
        choices={positionChoices}
      />

      <AgeSliderField
        label="Athlete age"
        source={CardField.SEARCH_ATHLETE_AGE}
      />

      <ReferenceInput
        label="Team"
        source={CardField.SEARCH_TEAM}
        reference="team"
        filterToQuery={(search: string) => ({ [TeamField.NAME]: search })}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <ReferenceInput
        label="Season"
        source={CardField.SEARCH_SEASON}
        reference="nftcardseason"
        filterToQuery={(search: string) => ({ title: search, isLive: true })}
      >
        <AutocompleteInput optionText="title" optionValue="id" />
      </ReferenceInput>

      <ReferenceInput
        label="Batch"
        source={CardField.SEARCH_BATCH}
        reference="nftbatch"
        filterToQuery={(search: string) => ({ label: search, isLive: true })}
      >
        <AutocompleteInput optionText="label" optionValue="id" />
      </ReferenceInput>
    </Filter>
  );
};

export const NFTCardList = (props: ICardListProps) => {
  const [saveFiltersDialogVisibility, setSaveFiltersDialogVisibility] =
    useState(false);
  const [filtersConfig, setFiltersConfig] = useState<FilterPayload>();
  const notify = useNotify();
  const [create] = useCreate('filter');

  const submitFilters = (formValues: KeyValueObject) => {
    if (!filtersConfig) {
      throw new Error('Invalid state: No filters to process');
    }

    create(
      {
        payload: {
          data: {
            ...formValues,
            query: reactAdminCardFilterToQuery(filtersConfig),
          },
        },
      },
      {
        onSuccess: () => {
          notify('Filters saved', 'info');
        },
        onFailure: () => {
          notify('Filters saving error', 'error');
        },
      }
    );
  };

  return (
    <>
      <List
        {...props}
        actions={
          <ListActions
            setFiltersConfig={setFiltersConfig}
            setSaveFiltersDialogVisibility={setSaveFiltersDialogVisibility}
          />
        }
        exporter={false}
        sort={{ field: '_id', order: 'ASC' }}
        filters={<CardFilter />}
      >
        <NFTCardGridList />
      </List>
      <SaveFiltersDialog
        submitFilters={submitFilters}
        filtersConfig={filtersConfig}
        saveFiltersDialogVisibility={saveFiltersDialogVisibility}
        setSaveFiltersDialogVisibility={setSaveFiltersDialogVisibility}
      />
    </>
  );
};
