import { Typography } from '@material-ui/core';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

import { Label, ShowRow } from './styles';

const SeasonTitle = ({ record }: any) => (
  <ShowRow>
    <Typography variant="body2" component="div">
      <Label>Season</Label>
      <ReferenceField source="season" reference="nftcardseason" record={record}>
        <TextField source="title" />
      </ReferenceField>
    </Typography>
  </ShowRow>
);

export default SeasonTitle;
