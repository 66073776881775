"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QUEUE = void 0;
var QUEUE;
(function (QUEUE) {
    QUEUE["BCI_CREATE_WALLET"] = "BCI_CREATE_WALLET";
    QUEUE["BCI_WALLET_CREATED"] = "BCI_WALLET_CREATED";
    QUEUE["BCI_MINT"] = "BCI_MINT";
    QUEUE["BCI_MINT_DONE"] = "BCI_MINT_DONE";
    QUEUE["BCI_MINT_ULC"] = "BCI_MINT_ULC";
    QUEUE["BCI_TRANSFER_NFT"] = "BCI_TRANSFER_NFT";
    QUEUE["BCI_TRANSFER_TOKEN"] = "BCI_TRANSFER_TOKEN";
    QUEUE["BCI_CREATE_SALE"] = "BCI_CREATE_SALE";
    QUEUE["BCI_DESTROY_SALE"] = "BCI_DESTROY_SALE";
    QUEUE["BCI_ACCEPT_SALE"] = "BCI_ACCEPT_SALE";
    QUEUE["BCI_MARKET_BUY"] = "BCI_MARKET_BUY";
    QUEUE["BCI_TOKEN_PAYMENT"] = "BCI_TOKEN_PAYMENT";
})(QUEUE = exports.QUEUE || (exports.QUEUE = {}));
