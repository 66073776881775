"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PLAYERS_MAX_LIMIT = exports.ScoreSort = void 0;
var ScoreSort;
(function (ScoreSort) {
    ScoreSort["GAMEWEEK"] = "GAMEWEEK";
    ScoreSort["SEASON"] = "SEASON";
    ScoreSort["SEASON_AVERAGE"] = "SEASON_AVERAGE";
})(ScoreSort = exports.ScoreSort || (exports.ScoreSort = {}));
exports.GET_PLAYERS_MAX_LIMIT = 20;
