import React from 'react';
import { Record, TextField } from 'react-admin';

interface IProps {
  source: string;
  record?: Record & {
    googleSpreadsheetId: string;
    googleSheetId: string;
  };
}

export const LootTableField = ({ source, record, ...props }: IProps) => {
  if (!record) {
    return null;
  }

  return (
    <a
      href={`https://docs.google.com/spreadsheets/d/${record.googleSpreadsheetId}/edit#gid=${record.googleSheetId}`}
      target="_blank"
      rel="noreferrer"
    >
      <TextField source={source} record={record} {...props} />
    </a>
  );
};
