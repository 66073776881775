"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFTUEBudget = exports.getTotalDraftFormation = exports.getFTUEDraftFormation = void 0;
function getFTUEDraftFormation() {
    return {
        goals: 1,
        defenders: 4,
        middles: 4,
        strikers: 2,
    };
}
exports.getFTUEDraftFormation = getFTUEDraftFormation;
function getTotalDraftFormation() {
    var _a = getFTUEDraftFormation(), goals = _a.goals, defenders = _a.defenders, middles = _a.middles, strikers = _a.strikers;
    return goals + defenders + middles + strikers;
}
exports.getTotalDraftFormation = getTotalDraftFormation;
function getFTUEBudget() {
    return 100;
}
exports.getFTUEBudget = getFTUEBudget;
