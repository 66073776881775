"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyEventType = exports.AthleteFoot = exports.AthletePosition = void 0;
var AthletePosition;
(function (AthletePosition) {
    AthletePosition["GOAL"] = "GOAL";
    AthletePosition["DEFENDER"] = "DEFENDER";
    AthletePosition["MIDDLE"] = "MIDDLE";
    AthletePosition["STRIKER"] = "STRIKER";
})(AthletePosition = exports.AthletePosition || (exports.AthletePosition = {}));
var AthleteFoot;
(function (AthleteFoot) {
    AthleteFoot["RIGHT"] = "RIGHT";
    AthleteFoot["LEFT"] = "LEFT";
    AthleteFoot["BOTH"] = "BOTH";
})(AthleteFoot = exports.AthleteFoot || (exports.AthleteFoot = {}));
var KeyEventType;
(function (KeyEventType) {
    KeyEventType["ASSIST"] = "ASSIST";
    KeyEventType["GOAL"] = "GOAL";
    KeyEventType["GOAL_SAVED"] = "GOAL_SAVED";
    KeyEventType["INJURY"] = "INJURY";
    KeyEventType["KEEPER_SWEEPER"] = "KEEPER_SWEEPER";
    KeyEventType["RED_CARD"] = "RED_CARD";
    KeyEventType["YELLOW_CARD"] = "YELLOW_CARD";
    KeyEventType["YELLOW_RED_CARD"] = "YELLOW_RED_CARD";
})(KeyEventType = exports.KeyEventType || (exports.KeyEventType = {}));
