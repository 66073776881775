import { Box, CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Create,
  DateTimeInput,
  NumberInput,
  SimpleForm,
  TextInput,
  useListController,
} from 'react-admin';
import { GameWeek } from 'ultimate-league-common';
import { buildQuery, fetchApi } from '~technical/api';
import { error } from '~technical/logger';

const SimpleGameWeekCreateForm = (props: any) => (
  <SimpleForm {...props}>
    <NumberInput source="position" />
    <TextInput source="title" />
    <DateTimeInput
      source="startDate"
      inputProps={{
        step: 3600,
      }}
    />
    <DateTimeInput
      source="endDate"
      inputProps={{
        step: 3600,
      }}
    />
  </SimpleForm>
);

export const GameWeekCreate = (props: any) => {
  const listContext = useListController(props);
  const [lastGameWeek, setLastGameWeek] = useState<GameWeek.IGameWeek>();
  const [fallback, setFallback] = useState(false);

  useEffect(() => {
    if (listContext.loading) {
      return;
    }

    fetchApi(
      `/backoffice/gameweek?${buildQuery({
        skip: 0,
        limit: 1,
        sort: '-position',
      })}`
    )
      .then((res) => res.json())
      .then(([gameweek]) => {
        if (!gameweek) {
          throw new Error('No gameweek exists yet');
        }
        setLastGameWeek(gameweek);
      })
      .catch((e) => {
        setLastGameWeek(undefined);
        setFallback(true);
        error(e);
      });
  }, [listContext.total, listContext.loading, setLastGameWeek, setFallback]);

  if (fallback) {
    return (
      <Create {...props}>
        <SimpleGameWeekCreateForm />
      </Create>
    );
  }

  if (listContext.loading || !lastGameWeek) {
    return (
      <Box margin="auto">
        <CircularProgress />
      </Box>
    );
  }

  const newGwPosition = lastGameWeek.position + 1;
  return (
    <Create {...props}>
      <SimpleForm>
        <NumberInput source="position" disabled initialValue={newGwPosition} />
        <TextInput source="title" initialValue={`GameWeek ${newGwPosition}`} />
        <DateTimeInput
          source="startDate"
          initialValue={new Date(lastGameWeek.endDate)}
          inputProps={{
            min: lastGameWeek.endDate.slice(0, 16),
            step: 3600,
          }}
        />
        <DateTimeInput
          source="endDate"
          initialValue={new Date(lastGameWeek.endDate)}
          inputProps={{
            min: lastGameWeek.endDate.slice(0, 16),
            step: 3600,
          }}
        />
      </SimpleForm>
    </Create>
  );
};
