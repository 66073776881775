import { makeStyles } from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FilterListIcon from '@material-ui/icons/FilterList';
import FlagIcon from '@material-ui/icons/Flag';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import GroupsIcon from '@material-ui/icons/Group';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import QueueIcon from '@material-ui/icons/Queue';
import RestoreIcon from '@material-ui/icons/Restore';
import TodayIcon from '@material-ui/icons/Today';
import DefaultIcon from '@material-ui/icons/ViewList';
import WarningIcon from '@material-ui/icons/Warning';
import * as React from 'react';
import { useState } from 'react';
import { MenuItemLink } from 'react-admin';

import { SubMenu } from './SubMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
}));

enum SubMenuName {
  DEVELOPMENT = 'Development',
  CARDS = 'Cards',
}

interface IProps {
  dense?: boolean;
}

export const Menu = ({ dense = false }: IProps) => {
  const classes = useStyles();

  const [state, setState] = useState<Record<SubMenuName, boolean>>({
    [SubMenuName.DEVELOPMENT]: false,
    [SubMenuName.CARDS]: false,
  });

  const handleToggle = (menu: SubMenuName) => () => {
    setState((oState) => ({ ...oState, [menu]: !oState[menu] }));
  };

  return (
    <div className={classes.root}>
      <MenuItemLink
        to="/competition"
        primaryText="Competitions"
        leftIcon={<FlagIcon />}
        dense={dense}
      />
      <SubMenu
        handleToggle={handleToggle(SubMenuName.CARDS)}
        isOpen={state[SubMenuName.CARDS]}
        name={SubMenuName.CARDS}
        icon={<CropPortraitIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/nftcardseason"
          primaryText="Seasons"
          leftIcon={<DateRangeIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/nftbatch"
          primaryText="Batches"
          leftIcon={<QueueIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/nftcard"
          primaryText="NFTs"
          leftIcon={<CropPortraitIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/NftCardStatsView"
          primaryText="NFTs stats"
          leftIcon={<FormatListNumberedRtlIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/filter"
          primaryText="Filters"
          leftIcon={<FilterListIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/packConfig"
          primaryText="Pack Configs"
          leftIcon={<CardGiftcardIcon />}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to="/team"
        primaryText="Teams"
        leftIcon={<GroupsIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/athlete"
        primaryText="Athletes"
        leftIcon={<DirectionsRunIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/gameweek"
        primaryText="GameWeeks"
        leftIcon={<TodayIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/match"
        primaryText="Matches"
        leftIcon={<DefaultIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/user"
        primaryText="Users"
        leftIcon={<PermIdentityIcon />}
        dense={dense}
      />
      <SubMenu
        handleToggle={handleToggle(SubMenuName.DEVELOPMENT)}
        isOpen={state[SubMenuName.DEVELOPMENT]}
        name={SubMenuName.DEVELOPMENT}
        icon={<WarningIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/backup"
          primaryText="Backups"
          leftIcon={<RestoreIcon />}
        />
        <MenuItemLink
          to="/cron"
          primaryText="Crons"
          leftIcon={<AccessAlarmIcon />}
        />
      </SubMenu>
    </div>
  );
};
