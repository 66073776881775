"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMatchStatDetailType = exports.isSubstitutedHighlight = exports.isCardHighlight = exports.isGoalHighlight = exports.CardType = exports.GoalType = exports.IMatchHighlightType = void 0;
var IMatchHighlightType;
(function (IMatchHighlightType) {
    IMatchHighlightType["GOAL"] = "goal";
    IMatchHighlightType["CARD"] = "card";
    IMatchHighlightType["SUBSTITUTE"] = "substitute";
})(IMatchHighlightType = exports.IMatchHighlightType || (exports.IMatchHighlightType = {}));
var GoalType;
(function (GoalType) {
    GoalType["GOAL"] = "G";
    GoalType["PENALTY"] = "PG";
    GoalType["OWN_GOAL"] = "OG";
})(GoalType = exports.GoalType || (exports.GoalType = {}));
var CardType;
(function (CardType) {
    CardType["YELLOW_CARD"] = "YC";
    CardType["RED_CARD"] = "RC";
    CardType["DOUBLE_YELLOW_CARD"] = "Y2C";
})(CardType = exports.CardType || (exports.CardType = {}));
function isGoalHighlight(highlight) {
    return highlight.highlightType === IMatchHighlightType.GOAL;
}
exports.isGoalHighlight = isGoalHighlight;
function isCardHighlight(highlight) {
    return highlight.highlightType === IMatchHighlightType.CARD;
}
exports.isCardHighlight = isCardHighlight;
function isSubstitutedHighlight(highlight) {
    return highlight.highlightType === IMatchHighlightType.SUBSTITUTE;
}
exports.isSubstitutedHighlight = isSubstitutedHighlight;
var IMatchStatDetailType;
(function (IMatchStatDetailType) {
    IMatchStatDetailType["SHOTS"] = "SHOTS";
    IMatchStatDetailType["SHOTS_ON_TARGET"] = "SHOTS_ON_TARGET";
    IMatchStatDetailType["POSSESSION"] = "POSSESSION";
    IMatchStatDetailType["PASSES"] = "PASSES";
    IMatchStatDetailType["SHOT_ACCURACY"] = "SHOT_ACCURACY";
    IMatchStatDetailType["PASS_ACCURACY"] = "PASS_ACCURACY";
    IMatchStatDetailType["FOULS"] = "FOULS";
    IMatchStatDetailType["YELLOW_CARD"] = "YELLOW_CARD";
    IMatchStatDetailType["RED_CARD"] = "RED_CARD";
    IMatchStatDetailType["CORNERS"] = "CORNERS";
    IMatchStatDetailType["OFFSIDES"] = "OFFSIDES";
})(IMatchStatDetailType = exports.IMatchStatDetailType || (exports.IMatchStatDetailType = {}));
