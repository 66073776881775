import React from 'react';
import { Create } from 'react-admin';

import NftBatchForm from './NftBatchForm';

export const NftBatchCreate = (props: {}) => (
  <Create {...props}>
    <NftBatchForm />
  </Create>
);
