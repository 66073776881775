import { Blockchain } from 'ultimate-league-common';
import { IInputRaritySupply } from '~business/common/assetTypes';

import { ISavedNftBatch } from './types';

export const getAthletesCount = (batch?: ISavedNftBatch) =>
  Array.isArray(batch?.athletes) ? batch!.athletes.length : 0;

export const hasCustomAssets = (batch?: ISavedNftBatch) =>
  batch?.rarities?.some((r: any) => typeof r.supply === 'number') === true;

export const hasCustomTemplate = (batch?: ISavedNftBatch) =>
  !!batch?.cardTemplate;

export type IRarityFile = Record<Blockchain.NFTCard.RarityLevel, string | null>;

/**
 * Transforms array of IRaritySupply[] to an object to ease form management
 */
export const raritySuppliesToObject = (
  rarityKeys: string[],
  rarities?: IInputRaritySupply[]
) =>
  Array.isArray(rarityKeys) !== true || Array.isArray(rarities) !== true
    ? {}
    : rarityKeys.reduce((acc: object, rarityKey: string) => {
        acc[rarityKey] = rarities!.find(
          (r) => r.level === Blockchain.NFTCard.RarityLevel[rarityKey]
        );
        return acc;
      }, {});
