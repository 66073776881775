import styled from 'styled-components';

export const Preview = styled.div`
  pointer-events: none;
  margin-top: 1em;
`;

interface IWrapperProps {
  scale: number;
}

const CARD_WIDTH = 600;
const CARD_HEIGHT = 960;
const MENU_AND_LINKS_HEIGHT = 110;

export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin: 0.5rem;
  padding: 0.5rem;
  width: ${({ scale }: IWrapperProps) => `${Math.floor(CARD_WIDTH * scale)}px`};
  height: ${({ scale }: IWrapperProps) =>
    `${Math.floor(CARD_HEIGHT * scale) + MENU_AND_LINKS_HEIGHT}px`};
  border: 2px solid lightgray;
  overflow: hidden;

  & ${Preview} {
    transform: ${({ scale }: IWrapperProps) => `scale(${scale})`};
    transform-origin: top;
    will-change: transform;
  }
`;

export const EditLinks = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin: 0.3rem 0.15rem 0;
  }
`;
