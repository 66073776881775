"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapInTeamAthletePosition = exports.MapAthletePositionInTeam = exports.PrizePoolType = exports.SpecificGameView = void 0;
var athlete_1 = require("../../soccer-data/athlete");
var SpecificGameView;
(function (SpecificGameView) {
    SpecificGameView["LEAGUES"] = "leagues";
})(SpecificGameView = exports.SpecificGameView || (exports.SpecificGameView = {}));
var PrizePoolType;
(function (PrizePoolType) {
    PrizePoolType["GAUGE"] = "GAUGE";
})(PrizePoolType = exports.PrizePoolType || (exports.PrizePoolType = {}));
exports.MapAthletePositionInTeam = (_a = {},
    _a[athlete_1.AthletePosition.GOAL] = 'goals',
    _a[athlete_1.AthletePosition.DEFENDER] = 'defenders',
    _a[athlete_1.AthletePosition.MIDDLE] = 'middles',
    _a[athlete_1.AthletePosition.STRIKER] = 'strikers',
    _a);
exports.MapInTeamAthletePosition = {
    goals: athlete_1.AthletePosition.GOAL,
    defenders: athlete_1.AthletePosition.DEFENDER,
    middles: athlete_1.AthletePosition.MIDDLE,
    strikers: athlete_1.AthletePosition.STRIKER,
};
