import { Blockchain, enumToArray, SoccerData } from 'ultimate-league-common';
import { IRarityAsset } from '~business/common/assetTypes';
import { IRarityFile } from '~business/nft-batch/common';

export const rarityKeys: string[] = enumToArray(Blockchain.NFTCard.RarityLevel);

export const athletePositions = enumToArray(SoccerData.Athlete.AthletePosition);

export const positionChoices = enumToArray(
  SoccerData.Athlete.AthletePosition
).map((position) => ({
  id: position,
  name: position,
}));

export const footChoices = enumToArray(SoccerData.Athlete.AthleteFoot).map(
  (foot) => ({
    id: foot,
    name: foot,
  })
);

export const tierChoices = [1, 2, 3].map((tier) => ({
  id: tier,
  name: String(tier),
}));

/**
 * Managing rarity assets as an array is a PITA, so we use an object instead
 */
export const rarityAssetsToObject = (
  keysOfRarities: string[],
  rarities?: IRarityAsset[]
) =>
  keysOfRarities.reduce<IRarityFile>((acc, rarityKey) => {
    const asset = rarities?.find(
      (r) => r.rarity === Blockchain.NFTCard.RarityLevel[rarityKey]
    );
    acc[rarityKey] = asset?.file || null;
    return acc;
  }, {} as IRarityFile);
