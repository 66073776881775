/* eslint-disable react/no-unused-prop-types */
import { makeStyles } from '@material-ui/styles';
import React, { KeyboardEventHandler, PropsWithChildren, useMemo } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  Filter,
  List,
  ListContextProvider,
  ListProps,
  ListView,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ShowButton,
  TextField,
  TextInput,
  useListController,
} from 'react-admin';
import { Storage } from 'ultimate-league-common';
import { AthleteBulkEditButton } from '~business/athlete/AthleteBulkEditButton';
import { AthleteField } from '~technical/filters/athlete';
import { AssetField } from '~ui/AssetField';
import { QuickFilter } from '~ui/QuickFilter';

const AthleteFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* TODO: fix types */
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <TextInput source="q" label="Athlete" />
      <ReferenceInput
        label="Team"
        source="team"
        reference="team"
        filterToQuery={(search: string) => ({ name: search })}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      {children}
    </Filter>
  );
};

const useListViewStyle = makeStyles({
  bulkActionsDisplayed: {
    marginTop: 0,
  },
});

const useDatagridStyle = makeStyles({
  headerCell: {
    '& input[type="checkbox"] ~ svg': {
      display: 'none',
    },
  },
});

interface IParams extends Omit<ListProps, 'resource' | 'basePath'> {
  onToggleItem: (toggleId: string) => void;
  selectedIds: string[];
}

export const useAthletesListController = ({
  onToggleItem,
  selectedIds,
  ...params
}: IParams) => {
  const controller = useListController({
    resource: 'athlete',
    basePath: '/athletes',
    ...params,
  });

  return useMemo(
    () => ({
      ...controller,
      onToggleItem,
      selectedIds,
    }),
    [controller, onToggleItem, selectedIds]
  );
};

interface IProps {
  contextValue: ReturnType<typeof useAthletesListController>;
}

export const AthleteListInContext = ({ contextValue }: IProps) => (
  <ListContextProvider value={contextValue}>
    <ListView
      resource="athlete"
      basePath="/athletes"
      exporter={false}
      filters={<AthleteFilter />}
      // @ts-ignore bulkActionButtons=null will hide actions but allow to select rows.
      bulkActionButtons={null}
      classes={useListViewStyle()}
    >
      <Datagrid classes={useDatagridStyle()} hasBulkActions={false}>
        <TextField source="matchName" />
        <ReferenceField source="club" reference="team" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="national" reference="team" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </ListView>
  </ListContextProvider>
);

const Actions = (props: any) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <ShowButton {...props} />
    <EditButton {...props} />
  </div>
);

export const AthleteList = (props: {}) => (
  <List
    {...props}
    filters={
      <AthleteFilter>
        <QuickFilter
          source={AthleteField.MISSING_DATA}
          label="Requires manual data"
          defaultValue
        />
      </AthleteFilter>
    }
    bulkActionButtons={<AthleteBulkEditButton />}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}
  >
    <Datagrid>
      <TextField source="matchName" />
      <NumberField source="tier" />
      <ReferenceField source="club" reference="team" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="shirtNumber" />
      <ReferenceField source="national" reference="team" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <AssetField
        assetParams={{
          storageType: Storage.StorageType.PUBLIC_ATHLETE_MAIN,
          format: Storage.AssetFormat.SMALL,
        }}
        source="assets.main"
        label="Main asset"
      />
      <Actions />
    </Datagrid>
  </List>
);
