import { processGenericFilter, Value } from './generic';
import { processTeamFilter } from './team';

export enum AthleteField {
  JOKER = 'q',
  TEAM = 'team',
  MISSING_DATA = 'missing_data',
}

export function processAthleteFilter(
  field: AthleteField | string,
  value: Value
) {
  switch (field) {
    case AthleteField.JOKER:
      return {
        $or: [
          { matchName: { $regex: value, $options: 'i' } },
          { firstName: { $regex: value, $options: 'i' } },
          { lastName: { $regex: value, $options: 'i' } },
        ],
      };
    case AthleteField.TEAM:
      if (value instanceof Array) {
        return {
          $or: [{ club: { $in: value } }, { national: { $in: value } }],
        };
      }

      return {
        $or: [
          { club: processTeamFilter('_id', value) },
          { national: processTeamFilter('_id', value) },
        ],
      };
    case AthleteField.MISSING_DATA:
      return {
        $or: [{ birth: null }, { 'assets.main': null }],
      };
    default:
      return processGenericFilter(field, value);
  }
}
