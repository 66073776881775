import { Authorization as AuthorizationCommon } from 'ultimate-league-common';

export class Authorization {
  private static instance: Authorization;

  public static getInstance() {
    if (!Authorization.instance) {
      Authorization.instance = new Authorization();
    }

    return Authorization.instance;
  }

  private constructor() {
    const item = localStorage.getItem('credentials');
    if (item) {
      this.credentials = JSON.parse(item);
    }
  }

  private credentials?: AuthorizationCommon.IAuthorization;

  public setCredentials(
    credentials: AuthorizationCommon.IAuthorization | undefined
  ): void {
    this.credentials = credentials;
    this.syncLocalStorage();
  }

  public setJWT(jwt: string): void {
    if (!this.credentials) {
      throw new Error('Missing credentials');
    }

    this.credentials.jwt = jwt;
    this.syncLocalStorage();
  }

  public getCredentials() {
    return this.credentials;
  }

  private syncLocalStorage() {
    if (this.credentials) {
      localStorage.setItem('credentials', JSON.stringify(this.credentials));
    } else {
      localStorage.removeItem('credentials');
    }
  }
}
