import { Box } from '@material-ui/core';
import { TextInput } from 'react-admin';
import styled from 'styled-components';

export const PositionAssetsWrapper = styled.div`
  margin: 0.2em 0 0.5em;
  padding: 0.5em 1em;
  border: 1px solid #959595;
  background-color: white;
`;

export const StorageImageInputContainer = styled(Box)`
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const PositionLabel = styled(TextInput)`
  & .muifilledinput-underline:before {
    border: 0;
  }
  & input {
    padding: 0.6em;
    font-weight: bold;
    color: grey;
    border: 0;
  }
`;
