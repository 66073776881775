import React, { useEffect, useState } from 'react';
import { linkToRecord } from 'react-admin';
import { Link } from 'react-router-dom';
import { SoccerData } from 'ultimate-league-common';
import { buildQuery, fetchApi } from '~technical/api';

import { ItemId } from './styles';

interface IBatchErrorDetailProps {
  teamId?: SoccerData.Team.ITeam['id'];
  reason: string;
}

export const BatchErrorDetail = ({
  teamId,
  reason,
}: IBatchErrorDetailProps) => {
  const [team, setTeam] = useState<string>();
  const teamLink = !teamId ? '' : linkToRecord('/team', teamId, 'edit');

  useEffect(() => {
    if (!teamId) {
      setTeam(undefined);
      return;
    }
    (async () => {
      const response = await fetchApi(
        `/backoffice/team/${teamId}?${buildQuery({
          select: 'name',
        })}`
      );
      const { name } = await response.json();
      setTeam(name || teamId);
    })();
  }, [teamId]);

  return (
    <li>
      {team && (
        <>
          <span>Team </span>
          <Link to={teamLink} target="_blank">
            {team}
          </Link>
          <ItemId>{` (#${teamId}) - `}</ItemId>
        </>
      )}
      {reason}
    </li>
  );
};
