import React from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ShowButton,
  TextField,
  TopToolbar,
} from 'react-admin';
import { BackupImport } from '~business/backup/BackupImport';

const ListActions = () => (
  <TopToolbar>
    <BackupImport />
    <CreateButton />
  </TopToolbar>
);

export const BackupList = (props: {}) => (
  <List {...props} actions={<ListActions />} exporter={false}>
    <Datagrid>
      <ReferenceField source="owner" reference="user" link={false}>
        <TextField source="username" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <TextField source="strategy" />
      <BooleanField source="ready" />
      <BooleanField source="error" />
      <ShowButton />
    </Datagrid>
  </List>
);
