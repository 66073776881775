import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { Blockchain } from 'ultimate-league-common';
import { WalletField } from '~business/wallet/WalletField';
import { fetchApi } from '~technical/api';

enum Status {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
}

interface IRecordWallet {
  id: string;
  address?: string;
  status: Status;
}

interface IProps {
  ownerId: string;
  record?: IRecordWallet;
}

export const WalletStatus = ({ record, ownerId }: IProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const setupWallet = useCallback(async () => {
    setLoading(true);
    try {
      await fetchApi(`/backoffice/user/${ownerId}/setupWallet`);
      notify('Setup submitted. Please wait a few minutes.', { type: 'info' });
    } catch (e) {
      notify(`Something went wrong: ${e.message}`, { type: 'error' });
      setLoading(false);
    }
  }, [ownerId, setLoading, notify]);

  useEffect(() => {
    if (!record || record.status !== Status.PENDING) {
      return () => {};
    }

    const ROUTINE_LOOP_MS = 10000;
    let stopped = false;
    let timeout: ReturnType<typeof setTimeout>;

    const checkResolved = async () => {
      const { data } = await dataProvider.getOne<IRecordWallet>(
        'wallet',
        record
      );

      if (data.status === Status.RESOLVED) {
        notify(
          `Wallet ${Blockchain.withPrefix(data.address!)} successfully setup !`,
          { type: 'info' }
        );
      } else if (!stopped) {
        timeout = setTimeout(checkResolved, ROUTINE_LOOP_MS);
      }
    };

    timeout = setTimeout(checkResolved, ROUTINE_LOOP_MS);

    return () => {
      clearTimeout(timeout);
      stopped = true;
    };
  }, [dataProvider, record, notify]);

  return (
    <>
      <WalletField record={record} />
      <br />
      {record?.status === Status.PENDING && (
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={setupWallet}
        >
          {loading ? 'Wait for setup...' : 'Setup wallet'}
        </Button>
      )}
    </>
  );
};
