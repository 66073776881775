import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import React, { useCallback, useState } from 'react';
import {
  BooleanField,
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useNotify,
} from 'react-admin';
import { EnvFlag } from '~business/env-warnings/EnvFlag';
import { API_URL, buildQuery, fetchApi } from '~technical/api';
import { Authorization } from '~technical/authorization';

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const BackupShowActions = ({
  data,
}: {
  data?: {
    id: string;
    strategy?: 'gcs';
    ready: boolean;
    error?: string;
    filename: string;
  };
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fetching, setFetching] = useState(false);

  const showDialog = useCallback(() => {
    setShowConfirmation(true);
  }, [setShowConfirmation]);

  const closeDialog = useCallback(() => {
    setShowConfirmation(false);
  }, [setShowConfirmation]);

  const handleRollback = useCallback(async () => {
    if (!data?.id) {
      throw new Error('Resource id missing');
    }
    setFetching(true);
    try {
      await fetchApi(`/backoffice/backup/${data?.id}/rollback`);
      closeDialog();
      notify('Database updated', 'info');
      setTimeout(() => setFetching(false), 0);
    } catch (e) {
      notify(e.message, 'error');
      setFetching(false);
      throw e;
    }
  }, [data?.id, closeDialog, notify]);

  return (
    <TopToolbar>
      <Dialog open={showConfirmation} onClose={closeDialog}>
        <DialogTitle>
          <EnvFlag /> Rollback database to this backup ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            All database changes that occurred after this backup will be lost
            forever.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {fetching ? (
            <CircularProgress style={{ margin: 24 }} />
          ) : (
            <>
              <Button
                onClick={closeDialog}
                color="primary"
                variant="contained"
                autoFocus
              >
                Cancel
              </Button>
              <Button onClick={handleRollback} color="secondary">
                Proceed
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {!data?.error && data?.ready && (
        <div className={classes.actionContainer}>
          {data.strategy !== 'gcs' && (
            <Button
              href={`${API_URL}/backoffice/backup/download/${
                data.filename
              }?${buildQuery({
                token: Authorization.getInstance().getCredentials()?.jwt,
              })}`}
              color="secondary"
              startIcon={<GetAppIcon />}
            >
              Download
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={showDialog}
            startIcon={<SettingsBackupRestoreIcon />}
          >
            Rollback
          </Button>
        </div>
      )}
    </TopToolbar>
  );
};

export const BackupShow = (props: {}) => (
  <Show actions={<BackupShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="description" />
      <ReferenceField source="owner" reference="user" link={false}>
        <TextField source="username" />
      </ReferenceField>
      <TextField source="filename" />
      <DateField source="createdAt" showTime />
      <BooleanField source="ready" />
      <TextField source="error" />
    </SimpleShowLayout>
  </Show>
);
