import * as React from 'react';
import { ComponentProps } from 'react';
import { AppBar as RaAppBar } from 'react-admin';
import styled from 'styled-components';
import { EnvBanner } from '~business/env-warnings/EnvBanner';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
`;

export const AppBar = (props: ComponentProps<typeof RaAppBar>) => (
  <Container>
    <EnvBanner />
    <RaAppBar {...props} position="relative" />
  </Container>
);
