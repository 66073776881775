"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d, _e, _f, _g;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNfts = exports.MAX_AGE = exports.MIN_AGE = exports.MAX_LIMIT = exports.acceptSale = exports.createSale = exports.transferNft = exports.NFT = void 0;
var pagination_1 = require("../../pagination");
var athlete_1 = require("../../soccer-data/athlete");
var technical_1 = require("../../technical");
var token_1 = require("../token");
var endpoint_1 = require("./endpoint");
var type_1 = require("./type");
exports.NFT = {
    numericality: {
        onlyInteger: true,
        greaterThan: 0,
    },
    presence: {
        allowEmpty: false,
    },
};
exports.transferNft = {
    to: technical_1.address,
    nft: exports.NFT,
};
exports.createSale = {
    weiPrice: token_1.weiAmount,
};
exports.acceptSale = {
    weiPrice: token_1.weiAmount,
};
exports.MAX_LIMIT = 24;
exports.MIN_AGE = 0;
exports.MAX_AGE = 200;
exports.getNfts = {
    offset: pagination_1.pagination.offset,
    limit: {
        numericality: __assign(__assign({}, pagination_1.pagination.limit.numericality), { lessThanOrEqualTo: exports.MAX_LIMIT }),
    },
    sort: {
        inclusion: technical_1.enumToArray(endpoint_1.NftsSort),
    },
    owner: {
        array: (_a = {},
            _a[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _a.format = technical_1.id.format,
            _a),
    },
    athlete: {
        array: (_b = {},
            _b[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _b.format = technical_1.id.format,
            _b),
    },
    'age.$gte': {
        numericality: {
            greaterThanOrEqualTo: exports.MIN_AGE,
            lessThanOrEqualTo: exports.MAX_AGE,
        },
    },
    'age.$lte': {
        numericality: {
            greaterThanOrEqualTo: exports.MIN_AGE,
            lessThanOrEqualTo: exports.MAX_AGE,
        },
    },
    position: {
        array: (_c = {},
            _c[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _c.inclusion = technical_1.enumToArray(athlete_1.AthletePosition),
            _c),
    },
    country: {
        array: (_d = {},
            _d[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _d.format = technical_1.id.format,
            _d),
    },
    club: {
        array: (_e = {},
            _e[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _e.format = technical_1.id.format,
            _e),
    },
    season: {
        array: (_f = {},
            _f[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _f.format = technical_1.id.format,
            _f),
    },
    level: {
        array: (_g = {},
            _g[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _g.inclusion = technical_1.enumToArray(type_1.RarityLevel, false),
            _g),
    },
};
