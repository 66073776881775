import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  EditButton,
  List,
  ShowButton,
  TextField,
  useRefresh,
} from 'react-admin';
import { fetchApi } from '~technical/api';

const never = () => false;

const Actions = (props: any) => {
  const { record } = props;
  const refresh = useRefresh();

  async function publish(seasonId: string) {
    await fetchApi(`/backoffice/nftcardseason/${seasonId}/publish`);
    refresh();
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ShowButton {...props} />
      {!record?.isLive && <EditButton {...props} />}
      {!record?.isLive && (
        <Button label="Publish" onClick={() => publish(record.id)}>
          <SendIcon />
        </Button>
      )}
    </div>
  );
};

export const SeasonList = (props: {}) => (
  <List {...props} exporter={false}>
    <Datagrid isRowSelectable={never}>
      <TextField source="title" />
      <DateField source="startDate" showTime />
      <DateField source="endDate" showTime />
      <BooleanField source="isLive" />
      <Actions />
    </Datagrid>
  </List>
);
