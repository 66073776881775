"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftsSort = void 0;
var NftsSort;
(function (NftsSort) {
    NftsSort["DEFAULT"] = "DEFAULT";
    NftsSort["LAST_UPDATE_ASC"] = "LAST_UPDATE_ASC";
    NftsSort["LAST_UPDATE_DESC"] = "LAST_UPDATE_DESC";
    NftsSort["PRICE_ASC"] = "PRICE_ASC";
    NftsSort["PRICE_DESC"] = "PRICE_DESC";
    NftsSort["SCORE_ASC"] = "SCORE_ASC";
    NftsSort["SCORE_DESC"] = "SCORE_DESC";
})(NftsSort = exports.NftsSort || (exports.NftsSort = {}));
