import { Storage } from 'ultimate-league-common';
import { uploadToStorage } from '~business/storage/service';
import { convertAsset, IAssetValue } from '~technical/storage';

import { IPackConfig } from './types';

export async function uploadPreviewToStorage(
  packConfigId: string,
  asset: IAssetValue,
  storageType:
    | Storage.StorageType.PUBLIC_PACK_MAJOR_PREVIEW
    | Storage.StorageType.PUBLIC_PACK_MINOR_PREVIEW
) {
  if (!asset?.medium) {
    return {
      small: null,
      medium: null,
    };
  }

  if (typeof asset.medium === 'string') {
    return {
      small: asset.small!,
      medium: asset.medium,
    };
  }

  return {
    small: await uploadToStorage(
      {
        type: storageType,
        packConfigId,
        format: Storage.AssetFormat.SMALL,
      },
      await convertAsset(asset.medium.rawFile)
    ),
    medium: await uploadToStorage(
      {
        type: storageType,
        packConfigId,
        format: Storage.AssetFormat.MEDIUM,
      },
      asset.medium.rawFile
    ),
  };
}

export const validateForm = (packConfig: IPackConfig) => {
  const errors: any = {};

  if (!packConfig.lootTable) {
    errors.lootTable = 'Selecting a loot table is mandatory';
  }

  // Check monetization values
  if (packConfig.isMonetizationEnabled && packConfig.monetization) {
    const { monetization: m } = packConfig;
    errors.monetization = {};

    if (typeof m?.price !== 'number') {
      errors.monetization.price = 'Price is mandatory';
    } else if (m.price < 0) {
      errors.monetization.price = 'Price cannot be negative';
    }

    if (typeof m?.supply !== 'number') {
      errors.monetization.supply = 'Supply is mandatory';
    } else if (m.supply < 0) {
      errors.monetization.supply = 'Supply cannot be negative';
    } else if (m.supply === 0) {
      errors.monetization.supply = 'Supply 0 does not make any sense, does it?';
    }

    const announcingDate = m?.announcingDate
      ? new Date(m.announcingDate)
      : null;
    const startDate = m?.startDate ? new Date(m.startDate) : null;
    if (!startDate || Number.isNaN(startDate.getTime())) {
      errors.monetization.startDate = 'Start date is mandatory';
    }
    const endDate = m?.endDate ? new Date(m.endDate) : null;
    if (!endDate || Number.isNaN(endDate.getTime())) {
      errors.monetization.endDate = 'End date is mandatory';
    }
    if (announcingDate) {
      if (startDate && startDate.getTime() < announcingDate.getTime()) {
        errors.monetization.startDate =
          "Start date can't be before announcing date";
      }
      if (endDate && endDate.getTime() < announcingDate.getTime()) {
        errors.monetization.endDate =
          "End date can't be before announcing date";
      }
    }
    if (startDate && endDate) {
      if (endDate.getTime() < startDate.getTime()) {
        errors.monetization.endDate = "End date can't be before start date";
      }
    }
  }

  return errors;
};
