import React from 'react';
import { ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { Storage } from 'ultimate-league-common';
import { AssetField } from '~ui/AssetField';

export const CompetitionShow = (props: any) => (
  <Show {...props} hasEdit={false}>
    <SimpleShowLayout>
      <TextField source="optaId" />
      <TextField source="name" />
      <ReferenceField source="country" reference="country" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" />
      <AssetField
        assetParams={{
          storageType: Storage.StorageType.PUBLIC_COMPETITION_ASSET,
          format: Storage.AssetFormat.MEDIUM,
        }}
        source="asset"
      />
    </SimpleShowLayout>
  </Show>
);
