"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ftueRegister = exports.getFTUECards = exports.MAX_PRICE = exports.MIN_PRICE = exports.FTUE_CARDS_MAX_LIMIT = exports.update = void 0;
var blockchain_1 = require("../blockchain");
var NFT_card_1 = require("../blockchain/NFT-card");
var localization_1 = require("../localization");
var pagination_1 = require("../pagination");
var athlete_1 = require("../soccer-data/athlete");
var technical_1 = require("../technical");
var ftue_service_1 = require("./ftue.service");
exports.update = {
    username: {
        presence: {
            allowEmpty: false,
        },
        format: {
            pattern: '^[a-zA-Z0-9_-]*$',
            message: localization_1.t('USERNAME_CONTAINING_ERROR'),
        },
        length: {
            maximum: 32,
            minimum: 3,
            tooShort: localization_1.t('USERNAME_TOO_SHORT_ERROR'),
            tooLong: localization_1.t('USERNAME_TOO_LONG_ERROR'),
        },
    },
    profilePicture: {
        format: technical_1.id.format,
    },
};
exports.FTUE_CARDS_MAX_LIMIT = 24;
// Careful, those values are used to compute FTUE prices
exports.MIN_PRICE = 4;
exports.MAX_PRICE = 20;
exports.getFTUECards = {
    offset: pagination_1.pagination.offset,
    limit: {
        numericality: __assign(__assign({}, pagination_1.pagination.limit.numericality), { lessThanOrEqualTo: exports.FTUE_CARDS_MAX_LIMIT }),
    },
    sort: {
        inclusion: technical_1.enumToArray(blockchain_1.NFTCard.NftsSort),
    },
    athlete: {
        array: (_a = {},
            _a[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _a.format = technical_1.id.format,
            _a),
    },
    'price.$gte': {
        numericality: {
            greaterThanOrEqualTo: exports.MIN_PRICE,
            lessThanOrEqualTo: exports.MAX_PRICE,
        },
    },
    'price.$lte': {
        numericality: {
            greaterThanOrEqualTo: exports.MIN_PRICE,
            lessThanOrEqualTo: exports.MAX_PRICE,
        },
    },
    'age.$gte': {
        numericality: {
            greaterThanOrEqualTo: NFT_card_1.MIN_AGE,
            lessThanOrEqualTo: NFT_card_1.MAX_AGE,
        },
    },
    'age.$lte': {
        numericality: {
            greaterThanOrEqualTo: NFT_card_1.MIN_AGE,
            lessThanOrEqualTo: NFT_card_1.MAX_AGE,
        },
    },
    position: {
        array: (_b = {},
            _b[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _b.inclusion = technical_1.enumToArray(athlete_1.AthletePosition),
            _b),
    },
    country: {
        array: (_c = {},
            _c[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _c.format = technical_1.id.format,
            _c),
    },
    club: {
        array: (_d = {},
            _d[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _d.format = technical_1.id.format,
            _d),
    },
};
exports.ftueRegister = {
    cards: {
        length: {
            is: ftue_service_1.getTotalDraftFormation(),
            message: localization_1.t('COMPLETE_SQUAD_MESSAGE_VALIDATEJS'),
        },
        presence: {
            allowEmpty: false,
        },
    },
};
